<ng-template #contenido let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Ingrese el código que acaba de recibir
        </h4>
    </div>
    <div class="contrato-modal">
        <div class="modal-content">
            <div class="modal-body contrato-modal">
                <div class="text-center">
                    <img class="img-message" src="../../../../../assets/img/ic-sending-message.svg" alt="icono mensaje whatsapp">
                </div>
                <div class="codigo-OTP">
                    <form [formGroup]="FormularioOTP" (ngSubmit)="onSubmit()" autocomplete="off">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text otp-ic"><img src="/assets/img/ic-Otp.svg" alt="código OTP"></div>
                            </div>
                            <input [attr.disabled]="(mostrarReintento || mostrarMensajeCodigoIncorrecto) ? true : null" type="tel" class="form-control otp-input" formControlName="otp" maxlength="4" (keydown.enter)="onSubmit()" appNumeros>
                        </div>
                    </form>
                    <div class="text-center">
                        <span class="help-block" *ngIf="FormularioOTP.get('otp').hasError('required') && FormularioOTP.get('otp').touched && !mostrarMensajeCodigoIncorrecto">
                            *Código de verificación es requerido
                        </span>
                        <span class="help-block" *ngIf="FormularioOTP.get('otp').hasError('maxlength') && FormularioOTP.get('otp').hasError('minlength') && 
                                                        FormularioOTP.get('otp').touched && !mostrarMensajeCodigoIncorrecto">
                            *Solo se permiten 4 números
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-2" *ngIf="!mostrarMensajeCodigoIncorrecto && !mostrarReintento">
                    <span class="opensans" [ngStyle]="{ display: 'Block' }">
                        Tienes <strong>{{ this.contador | formatTimer }}</strong> segundos, para digitar tu código de verificación
                    </span>
                </div>
            </div>
            <div class="modal-footer text-center acomodar-botones">
                <button type="button" class="boton-secundario" data-dismiss="modal" (click)="cerrarModal()" *ngIf="mostrarReintento">
                    Cancelar
                </button>
                <button type="button" class="boton-primario subir-boton" (click)="onSubmit()" *ngIf="!mostrarReintento">
                    Aceptar
                </button>
            </div>
        </div>
    </div>
</ng-template>