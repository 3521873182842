<div *ngIf= "mostrarPasarela">
    <section class="fondo-wizard">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="wizard-cont">
                        <div class="item-wizard">
                            <div class="{{opcionesCssDelaBarra[0]}} number">
                                <span>1</span>
                            </div>
                            <div  *ngIf ="mostrarValidacionUsuario"  class="info-step">
                                <strong class="primer-paso">Primer paso</strong>
                                <span title="Verificación de identidad">Verificar identidad</span>
                            </div>
                        </div>
                        <div class="item-wizard">
                            <div class="{{opcionesCssDelaBarra[1]}} number">
                                <span>2</span>
                            </div>
                            <div  *ngIf ="mostrarPlanDePagos"  class="info-step">
                                <strong class="segundo-paso">Segundo paso</strong>
                                <span title="Selección plan de pagos">Cuotas a pagar</span>
                            </div>
                        </div>
                        <div class="item-wizard">
                            <div class="{{opcionesCssDelaBarra[2]}} number">
                                <span>3</span>
                            </div>
                            <div *ngIf ="mostrarSolicitudLlamada" class="info-step">
                                <strong class="tercer-paso">Tercer paso</strong>
                                <span title="Firma de contrato">Solicitar llamada</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
    <div>
       <app-validar-usuario #validarcliente></app-validar-usuario>
       <app-planes-pago #planespago></app-planes-pago>
       <app-solicitud-llamada #solicitudLlamada></app-solicitud-llamada>
       <app-mensaje-modal></app-mensaje-modal>
    </div>

