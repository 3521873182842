import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Parametro } from '../models/parametro';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService extends BaseService {

  constructor(protected http: HttpClient) { super(http); }

  public consulta( nombreParametro: string) {
    const endpont = `${environment.pagoCreditoDigitalEndPoint}Parametros/${nombreParametro}`;
    return this.doGet<Parametro>(endpont, this.optsName('consultar parametro'));
  }
}
