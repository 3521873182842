<div class="plan-pago" *ngIf="mostrarPantalla">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <app-ayuda-contextual [icono]="SomeIcon" [descripcion]="Description"></app-ayuda-contextual>
            </div>
        </div>
        <app-modal-contrato #contrato></app-modal-contrato>
        <app-detalle-plan-pago #detallePago (ocultarDetalle)="ocultarDetalle($event)">
        </app-detalle-plan-pago>
        <section class="margin-bottom-16">
            <h3 class="titulo margin-title">Cuotas sugeridas</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4 mt-2" *ngFor="let planPago of listaPlanPagos">
                            <app-plan-pago [colorBoton]="seleccionarPreferida(planPago)" [planPago]="planPago" [frecuencia]="frecuencia" (opcionEscogida)="mostrarDetalle($event)" (seleccionarDetalle)="seleccionarPlanPago($event)"></app-plan-pago>
                        </div>
                    </div>
                    <div *ngIf="!verTodosActivado" class="d-flex justify-content-center margin-title">
                        <img class="ancho-img" src="../../../assets/img/ic-agaval-arrowdown.svg" alt="">
                        <button [hidden]="verTodosActivado" class="boton-link2 boton-link2__big" (click)="verTodosClick()">{{verTodosTexto}}</button>
                    </div>
                </div>
            </div>
        </section>

    </div>
    <div class="cont-bottom">
        <div class="contrato">
            <label class="container-check">Debes aceptar el contrato
                del crédito para continuar con el proceso <a class="boton-contrato" href="javascript:void(0)"
                (click)="abrirContrato()"><span class="underlined">Ver contrato</span><span class="requerido">*</span></a>
                <input type="checkbox" (change)="aceptarContrato($event)">
                <span class="checkmark"></span>
            </label>
        </div>
        <section class="footer-agaval">
            <div class="d-flex justify-content-center">
                <button class="boton-secundario" (click)="generarMensajeCancelacion()" [disabled]="desHabilitarCancelar">Cancelar</button>
                <button id="btn-firmar-credito" class="boton-primario ml-3" [disabled]="deshabilitarFirma" (click)="iniciarFirma()">Firmar crédito</button>
            </div>
        </section>
    </div>
</div>