import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MensajeActualizacion } from '../models/mensajeActualizacion';
import { RespuestaSolicitudActualizacionTerceros } from '../models/respuestaSolicitudActualizacionTerceros';
import { RespuestaFrontClienteActualizacion } from '../models/respuestaFrontClienteActualizacion';

@Injectable({
  providedIn: 'root'
})
export class RedireccionActualizacionService extends BaseService {

  constructor(protected http: HttpClient) { super(http); }

  public redireccionActualizacion(informacion: RespuestaFrontClienteActualizacion) {
    const informacionActualizacion = new MensajeActualizacion();
    informacionActualizacion.numeroDocumento = informacion.numeroDocumentoIdentidad;
    informacionActualizacion.urlRetornoNegativa = environment.pagoCreditoDigitalFront + informacion.idCompra;
    informacionActualizacion.urlRetornoPositiva = environment.pagoCreditoDigitalFront + informacion.idCompra;
    return this.consumirServicioRecepcionActualizacion(informacionActualizacion).subscribe(respuesta => {
      window.location.href = environment.urlActualizacionFront + respuesta.idSolicitud;
    });
  }

  private consumirServicioRecepcionActualizacion(informacionActualizacion: MensajeActualizacion) {
    const endpont = `${environment.urlActualizacionBack}SolicitudActualizacionTerceros/CrearSolicitud`;
    return this.doPost<MensajeActualizacion, RespuestaSolicitudActualizacionTerceros>(endpont,
      informacionActualizacion, this.optsName('consultar mensajes'));
  }

  public consumirServicioObtenerInformacionActualizar(idRastreo: string) {
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}ActualizarDatos/${idRastreo}`;
    const resultado = this.doGet<RespuestaFrontClienteActualizacion>(endPoint, this.optsName('Obtiene informacion actualizar'));
    return resultado;
  }

}
