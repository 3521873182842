<!-- --- -->
<section class="header-agaval">
    <div class="container">
        <header class="d-flex justify-content-between align-items-center">
            <div>
                <img src="../../../assets/logo-agaval.png" alt="Logo Agaval">
            </div>
            <p class="texto-link">{{ nombreCliente }}</p>
        </header>
    </div>
</section>
<div *ngIf="visiblePasarela" class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex justify-content-between align-items-center">
                <h1 class="encabezado margin-title">Tu crédito Agaval</h1>
                <div>
                    <p class="textomini gris opensans text-right">¿Necesitas ayuda?</p>
                    <a href="tel:+6044444999" class="boton-link opensans">604 4444 999</a> <span class="opensans"> Opción 4 </span>
                </div>
            </div>
        </div>
    </div>
</div>
<app-cargando #cargando></app-cargando>
<app-cargando-llamada #cargandoLlamada></app-cargando-llamada>
<ng-template #ampliacionCupoTemplate>
    <app-ampliacion-cupo [mensaje]='mensajeAmpliacion' (tiempoFinalizado)='tiempoFinalizadoAmpliacion()'></app-ampliacion-cupo>
</ng-template>
<app-pasarela [ngStyle]="(visiblePasarela) ? {'visibility': 'visible'} : {'visibility': 'hidden'}"></app-pasarela>