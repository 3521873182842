<div (click)="seleccionarPlanDepago(planPago)" class="{{colorBoton}} card-planes d-flex justify-content-between align-items-center">
    <div class="info-plan">
        <span>{{planPago?.numeroCuotas}} {{mensajeCuota}}</span>

        <h4>${{valorCuota | number }}</h4>
    </div>
    <div class="cont-boton mr-4">
        <div class="cuotaRegalada opensans" *ngIf="cuotasRegaladas>0">
            <a class="ver-detalle">
                <img src="/assets/img/ic-check-agaval.svg" alt="icono-cuota-regalada"> {{mensajeCuotaRegalada}}
            </a>
        </div>
        <button class="boton-detalle">
            <a class="ver-detalle" href="javascript:void(0)" (click)="cargarDetalles(planPago)">Ver detalle</a>
        </button>
    </div>
</div>