import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Alerta } from '../models/alerta';
import { TipoAlerta } from '../models/enumTipoAlerta';
@Injectable({
  providedIn: 'root'
})
export class AlertasService {
  private subject = new Subject<Alerta>();
  private continuatDespuesRutaCambie = false;
constructor(private router: Router) {
  router.events.subscribe(event => {
    if (event instanceof NavigationStart) {
        if (this.continuatDespuesRutaCambie) {
            this.continuatDespuesRutaCambie = false;
        } else {
            this.clear();
        }
    }
});
}

getAlert(): Observable<any> {
  return this.subject.asObservable();
}

completado(mensaje: string, continuatDespuesRutaCambie = false) {
  this.alerta(TipoAlerta.Completado, mensaje, continuatDespuesRutaCambie);
}

error(mensaje: string, continuatDespuesRutaCambie = false) {
  this.alerta(TipoAlerta.Error, mensaje, continuatDespuesRutaCambie);
}

info(mensaje: string, continuatDespuesRutaCambie = false) {
  this.alerta(TipoAlerta.Informativa, mensaje, continuatDespuesRutaCambie);
}

warn(mensaje: string, continuatDespuesRutaCambie = false) {
  this.alerta(TipoAlerta.Advertencia, mensaje, continuatDespuesRutaCambie);
}

alerta(tipo: TipoAlerta, mensaje: string, keepAfterRouteChange = false) {
  this.continuatDespuesRutaCambie = keepAfterRouteChange;
  const mensajeAlerta = new Alerta();
  mensajeAlerta.mensaje = mensaje;
  mensajeAlerta.tipoAlerta = tipo;
  this.subject.next(mensajeAlerta);
}

clear() {
  // clear alerts
  this.subject.next(null);
}
}
