import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './feature/home/home.component';
import { AlertaComponent } from './core/components/alerta/alerta.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TuCreditoAgavalModule } from './feature/tu-credito-agaval/tu-credito-agaval.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CargandoComponent } from './feature/tu-credito-agaval/components/shared/components/cargando/cargando.component';
import { CargandoLlamadaComponent } from './feature/tu-credito-agaval/components/shared/components/cargando-llamada/cargando-llamada.component';
import { TokenInterceptor } from './core/services/token-interceptor';
registerLocaleData(es);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AlertaComponent,
    CargandoComponent,
    CargandoLlamadaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TuCreditoAgavalModule,
    NgbModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

