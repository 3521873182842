import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { environment } from 'src/environments/environment';
import { MensajeVinculacion } from '../models/mensajeVinculacion';
import { RespuestaRedireccionVinculacion } from '../models/respuestaRedireccionVinculacion';

@Injectable({
  providedIn: 'root'
})
export class RedireccionVinculacionService extends BaseService {

  constructor(protected http: HttpClient) { super(http); }

  public redireccionarVinculacion( informacionVinculacion: MensajeVinculacion, urlVincuacion: string) {
    return this.consumirServicioRecepcionVinculacion(informacionVinculacion).subscribe(respuesta => {
      window.location.href = urlVincuacion.replace('{0}', respuesta.id);
    });
  }

  private consumirServicioRecepcionVinculacion(informacionVinculacion: MensajeVinculacion) {
    const endpont = `${environment.urlVinculacionBack}Compra/RecepcionClienteComprasVinculacion`;
    return this.doPost<MensajeVinculacion, RespuestaRedireccionVinculacion>(endpont, informacionVinculacion, this.optsName('consultar mensajes'));
  }
}


