import { TipoMensajeModal } from './enumTipoMensajeModal';
import { OpcionPasarela } from 'src/app/feature/tu-credito-agaval/components/pasarela/components/models/enumOpcionPasarela';
import { TipoAlerta } from './enumTipoAlerta';

export class MensajeModal {
    mensaje: string;
    tipoModal: TipoMensajeModal;
    tituloBotonPrimario: string;
    tituloBotonSecundario: string;
    icono?: string;
    opcion: OpcionPasarela;
    conservarDespuesCambioRuta: boolean;
    tipoMensajeAlerta: TipoAlerta;
    urlRetorno?: string;
    data?: any;
    basico: boolean;
    constructor(init?: Partial<MensajeModal>) {
        Object.assign(this, init);
    }
}
