export const environment = {
  production: true,
  pagoCreditoDigitalEndPoint: 'https://apis.agaval.com/compra-dev/api/',
  pagoCreditoDigitalFront: 'https://tucreditolab.agaval.com/',
  signalR : 'https://notificacionespushlab.agaval.com/ws/notify',
  urlAgavalPorDefecto: '/',
  urlVinculacionFront: 'https://solicitudlab.agaval.com/?solicitud=',
  urlVinculacionBack: 'https://solicitudlab.agaval.com/vinculacion/api/',
  urlActualizacionFront: 'https://actualizacionlab.agaval.com/#/?solicitud=',
  urlActualizacionBack: 'https://actualizacionlab.agaval.com/actualizacion/api/'
};
