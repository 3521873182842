import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { interval } from 'rxjs';
import { parametros } from '../shared/parametros/parametros';

@Component({
  selector: 'app-ampliacion-cupo',
  templateUrl: './ampliacion-cupo.component.html',
  styleUrls: ['./ampliacion-cupo.component.sass']
})
export class AmpliacionCupoComponent implements OnInit {
  progressbarValue = parametros.progressbarValue;
  segundos = parametros.segundos;
  sub;
  curSec = 0;
  contador;
  @Input() mensaje;
  @Output() tiempoFinalizado = new EventEmitter();
  homeTemplate: any;

  ngOnInit() {
    const tiempoAmpliacion = 380;
    this.startTimer(tiempoAmpliacion);
  }

  startTimer(seconds: number) {
    const intervalo = 500;
    const timer$ = interval(intervalo);
    this.sub = timer$.subscribe((sec) => {
      this.progressbarValue = this.segundos - sec * this.segundos / seconds;
      this.contador = (seconds - sec) / parametros.velocidadProgressBar;
      this.curSec = sec;

      if (this.curSec === seconds) {
        this.sub.unsubscribe();
        this.tiempoFinalizado.emit();
      }
    });
  }

}
