import { Component, Input } from '@angular/core';
import { DetallePlanDePago } from '../../shared/models/detallePlanDePago';

@Component({
  selector: 'app-detalle-pago',
  templateUrl: './detalle-pago.component.html'
})
export class DetallePagoComponent {
  @Input() detallePago: DetallePlanDePago;

}
