import { Component, Output, EventEmitter } from '@angular/core';
import { timer, Subject } from 'rxjs';
import { Temporizador } from '../../models/temporizador';
import { takeUntil, repeat } from 'rxjs/operators';

@Component({
  selector: 'app-temporizacion',
  templateUrl: './temporizacion.component.html'
})
export class TemporizacionComponent {

  private readonly parar = new Subject<void>();
  private readonly iniciar = new Subject<void>();
  @Output() mostrarTimeOut = new EventEmitter<Temporizador>();

  IniciarTemporizacion( tiempo: number) {
      const temporizador = timer(tiempo);
      temporizador.subscribe(val => {
        this.mostrarTimeOut.emit(new Temporizador(true, false));
      } );
  }

  IniciarTemporizacionPendiente( tiempo: number) {
    const temporizador = timer(tiempo)
    .pipe(
      takeUntil(this.parar),
      repeat({ delay: () => this.iniciar})
    );
    temporizador.subscribe(val => {
      this.mostrarTimeOut.emit(new Temporizador(true, true));
    });
  }

  start(): void {
    this.iniciar.next();
  }
  stop(): void {
    this.parar.next();
  }
}
