import { Component, OnInit } from '@angular/core';
import { PagoCreditoService } from '../shared/services/pago-credito.service';
import { AlertasService } from 'src/app/core/services/Alertas.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Sesion } from 'src/app/core/models/sesion';
import { CodigoFinalizacion } from '../shared/models/enumCodigoFinalizacion';
import { CancelarPago } from '../shared/models/enumCancelarPago';
import { Mensaje } from '../shared/models/mensaje';
import { MensajeModalService } from 'src/app/core/services/mensaje-modal.service';
import { TipoMensajeModal } from 'src/app/core/models/enumTipoMensajeModal';
import { OpcionPasarela } from '../pasarela/components/models/enumOpcionPasarela';
import { TipoAlerta } from 'src/app/core/models/enumTipoAlerta';
import { MensajeService } from '../shared/services/mensaje.service';
import { ResultadoCancelacionProceso } from '../shared/models/resultadoCancelacionProceso';
import { CargandoService } from 'src/app/core/services/cargando.service';
import { CancelarProceso } from '../shared/models/cancelarProceso';
import { RedireccionActualizacionService } from '../shared/services/redireccion-actualizacion.service';

@Component({
  selector: 'app-validacion-celular',
  templateUrl: './validacion-celular.component.html',
  styleUrls: ['./validacion-celular.component.sass']
})
export class ValidacionCelularComponent implements OnInit {
  mensaje: Mensaje;
  mostrarPantalla = false;
  private sesion: Sesion;
  public SomeIcon = '../../../assets/img/ic-agaval-bulb.svg';
  public Description = '¿Aún usas el número celular que aparece en la parte inferiror? Ten en cuenta que lo necesitarás más adelante';

  deshabilitarAceptar = false;
  desHabilitarCancelar = false;

  constructor(
    private servicioPagoCredito: PagoCreditoService,
    private servicioAlerta: AlertasService,
    private servicioSesion: SesionService,
    private servicioMensaje: MensajeService,
    private servicioMensajesModal: MensajeModalService,
    private cargandoService: CargandoService,
    private redireccionActualizarService: RedireccionActualizacionService ) {
      this.mensaje = new Mensaje();
  }

  ngOnInit() {
    this.servicioMensajesModal.ValidacionCelular.subscribe( resultado => {
      if (resultado === 'SI') {
        this.cancelarProceso();
      } else {
        this.deshabilitarAceptar = false;
        this.desHabilitarCancelar = false;
      }
    });
  }

  sleep(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
  }

  cancelarProceso() {
    this.sesion = this.servicioSesion.obtenerSesionActual();
    const cancelar: CancelarProceso = {
      idrastreo: this.sesion.conexionId ,
      idorden: '',
      idproceso : CancelarPago.ValidacionCliente,
      codigoFinalizacion: CodigoFinalizacion.ValidacionCelular
    };
    this.servicioPagoCredito.cancelarProcesoPago(cancelar).subscribe((resultado: ResultadoCancelacionProceso) => {
      this.mostrarPantalla = false;
      this.cargandoService.cambiarEstadoCargando(true);
      if (this.servicioMensajesModal.inIframe()) {
        window.parent.postMessage('RetornoNegativo', '*');
      } else {
        window.location.href = resultado.urlRetornoNegativo;
      }
    });
  }

  accionCancelar() {
    this.desHabilitarCancelar = true;
    this.deshabilitarAceptar = true;
    this.servicioMensaje.consulta('NOTIFICACION_CANCELAR_PAGO').subscribe( resultado => {
      this.servicioMensajesModal.mostratrModalMultiopcion(resultado.descripcion, TipoMensajeModal.confirmacion, 'SI', 'NO',
      OpcionPasarela.ValidacionCelular, TipoAlerta.Informativa);
      });
  }

  actualizarDatos() {
    this.desHabilitarCancelar = true;
    this.deshabilitarAceptar = true;
    this.cargandoService.cambiarEstadoCargando(true);
    this.sesion = this.servicioSesion.obtenerSesionActual();
    this.redireccionActualizarService.consumirServicioObtenerInformacionActualizar(this.sesion.conexionId).subscribe(mensaje => {
      this.servicioMensajesModal.mostratrModalUnaOpcion(mensaje.mensajeModal, TipoMensajeModal.confirmacion, 'Entendido',
        OpcionPasarela.RedireccionActualizacion, TipoAlerta.Informativa, null, mensaje);
    });
  }

  asignarMensaje(mensaje: Mensaje) {
    this.mensaje = mensaje;
    const indice: number = this.mensaje.descripcion.indexOf('*');
    const celular = this.mensaje.descripcion.substring(indice, mensaje.descripcion.length);
    this.Description = this.mensaje.descripcion.substring(0, indice - 1);
    this.mensaje.descripcion = celular;

  }

  accionAceptar() {
    this.desHabilitarCancelar = true;
    this.deshabilitarAceptar = true;
    this.mostrarPantalla = false;
    this.cargandoService.cambiarEstadoCargando(true);
    this.sesion = this.servicioSesion.obtenerSesionActual();
    this.servicioPagoCredito.crearPlanDePago(this.sesion.conexionId).subscribe(resultado => {
      this.deshabilitarAceptar = false;
      this.desHabilitarCancelar = false;
      this.mostrarPantalla = true;
      this.cargandoService.cambiarEstadoCargando(false);
      if (!resultado) {
        this.servicioAlerta.completado('Error al solicitar plan de pagos.');
      }
    });
  }

}
