<div *ngIf="mostrarPantalla" class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex justify-content-center">
                <div class="card-agaval">
                    <h3>
                        Para Agaval es importante tu seguridad
                    </h3>
                    <app-ayuda-contextual [icono]="SomeIcon" [descripcion]="Description">
                    </app-ayuda-contextual>
                    <img class="img-card" src="../../../../../assets/img/ic-agaval-celverification.png" alt="">
                    <div class="numero-celular mb-4">
                        <div class="texto-cel">
                            <strong class="numero-celular-titulo">Número de celular</strong>
                            <span>{{mensaje.descripcion}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cont-bottom">
        <section class="footer-agaval">
            <div class="d-flex justify-content-center">
                <button id="btn-cancelar" class="boton-secundario" (click)="actualizarDatos()" [disabled]="desHabilitarCancelar">Cancelar</button>
                <button class="boton-primario ml-3" (click)="accionAceptar()" [disabled]="deshabilitarAceptar">Aceptar</button>
            </div>
        </section>
    </div>
</div>