<app-modal-otp #otp></app-modal-otp>
<ng-template #contenidoSolicitudOtp let-modal>
  <div class="modal-content">
      <div class="modal-body">          
          <div class="row">              
            <div class="col-md-12">
              <div class="text-center">
                <img class="img-message" src="../../../../../assets/img/ic-sending-message.svg" alt="icono mensaje whatsapp">
              </div>
              <div class="text-modal opensans text-center mb-0">
                Recibirás un código de validación a tu Whatsapp y también lo recibirás como mensaje de texto
              </div>
          </div>
          </div>
      </div>
      <div class="text-center acomodar-botones">
        <div >
          <button type="button" class="boton-primario" data-dismiss="modal" (click)="submit()">
            Entendido
          </button>
        </div>
      </div>
  </div>
</ng-template>