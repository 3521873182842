import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResultadoConsulta } from '../models/resultadoConsulta';
import { CancelarProceso } from '../models/cancelarProceso';
import { ResultadoProceso } from '../models/resultadoProceso';
import { CrearPlanPago } from '../models/crearPlanPago';
import { RespuestaIniciarFirma } from '../models/respuestaIniciarFirma';
import { ResultadoCancelacionProceso } from '../models/resultadoCancelacionProceso';
import { ReintentoLlamada } from '../models/reintentoLlamada';
import { CancelarProcesoOtp } from '../models/cancelarProcesoOtp';

@Injectable({
  providedIn: 'root'
})
export class PagoCreditoService extends BaseService {
  constructor(protected http: HttpClient) { super(http); }

  public iniciarPagoConCredito( orden: string , tokenId: string , idConexion: string, existeEstadoPago: boolean, tipoCredito?: number) {
    
    let parametros = `Pago/${orden}/${idConexion}/${tokenId}/${existeEstadoPago}`;
    if(tipoCredito)
      parametros = `Pago/${orden}/${idConexion}/${tokenId}/${existeEstadoPago}/${tipoCredito}`;
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}${parametros}`;
    return this.doGet<ResultadoConsulta>(endPoint, this.optsName('iniciar Pago Con Credito'));
  }

  public iniciarPagoConCreditoIntegracion( orden: string , documentoCliente: string) {
    const parametros = `Pago/${orden}/${documentoCliente}`;
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}${parametros}`;
    return this.doGet<ResultadoConsulta>(endPoint, this.optsName('iniciar Pago Con Credito'));
  }

  public crearPlanDePago( rastreoId: string) {
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}PlanDePagos/${rastreoId}`;
    const crear: CrearPlanPago = { idrastreo: rastreoId };
    return this.doPost<CrearPlanPago, boolean>(endPoint, crear, this.optsName('crear plan de pago'));
  }

  public cargarMensajePlanDPagos( idConexion: string) {
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}PlanDePagos/instructivo/${idConexion}`;
    const resultado: any = this.doGet<ResultadoProceso>(endPoint, this.optsName('Carga el mensaje de plan de pagos'));
    return resultado;
  }

  public crearFirma( rastreoId: string  , numeroCuotas: number) {
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}FirmaDigital/instructivo/${rastreoId}/${numeroCuotas}`;
    return this.doGet<RespuestaIniciarFirma>(endPoint, this.optsName('Iniciar firma'));
  }

  public reintentoLlamada( rastreoId: string ) {
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}FirmaDigital/reintentoLlamada/${rastreoId}`;
    return this.doGet<ReintentoLlamada>(endPoint, this.optsName('Reintentar llamada pendiente'));
  }

  public hacerLlamada( respuesta: RespuestaIniciarFirma , idRastreo: string ) {
    const ruta = 'FirmaDigital/llamada';
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}${ruta}/${idRastreo}/${respuesta.otpNumero}`;
    return this.doGet<boolean>(endPoint, this.optsName('realiza la llamada'));
  }
  public cancelarProcesoPago( cancelar: CancelarProceso) {
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}Pago`;
    return this.doPost<CancelarProceso, ResultadoCancelacionProceso>(endPoint, cancelar, this.optsName('cancelar Pago Con Creditpo'));
  }

  public cancelarProcesoPagoOtp( cancelar: CancelarProcesoOtp) {
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}Pago/CancelarPago`;
    return this.doPost<CancelarProcesoOtp, ResultadoCancelacionProceso>(endPoint, cancelar, this.optsName('cancelar Pago firma Otp'));
  }

  public enviarUrlCompra(idOrden: string) {
    const endpoint = `${environment.pagoCreditoDigitalEndPoint}Pago/enviarurlcompra/${idOrden}`;
    return this.doGet<any>(endpoint, this.optsName('Enviar email ruta pago'));
  }

}
