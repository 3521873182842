<div class="container text-center">
    <div class="row">
        <div class="col-sm-8 offset-sm-2">
            <div *ngFor="let alerta of alertas" class="{{ cssClass(alerta) }} alert-dismissable">
                <a href="javascript:void(0)" class="close" (click)="removeAlerta(alerta)">
            &times;
          </a>
                <div>
                    {{alerta.mensaje}}
                </div>
            </div>
        </div>
    </div>
</div>