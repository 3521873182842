import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PlanDePagos } from 'src/app/feature/tu-credito-agaval/components/shared/models/planDePagos';

@Component({
  selector: 'app-plan-pago',
  templateUrl: './plan-pago.component.html',
  styleUrls: ['./plan-pago.component.sass']
})
export class PlanPagoComponent implements OnInit , OnChanges {  
  @Input() planPago: PlanDePagos;
  @Input() frecuencia: number;
  @Output() private opcionEscogida = new EventEmitter<PlanDePagos>();
  @Output() seleccionarDetalle = new EventEmitter<string>();
  @Input() colorBoton: string;
  mensajeCuota = 'cuotas quincenales';
 
  valorCuota: number;
  cuotasRegaladas = 0;
  mensajeCuotaRegalada: string;

  ngOnInit() {
    if (this.planPago != null ) {
      this.valorCuota = 0;
      if (this.planPago.detallesPlanDePago != null) {
        for (const detallePlan of this.planPago.detallesPlanDePago) {
          if (detallePlan.valorCuota > 0) {
            this.valorCuota = this.valorCuota > 0
                             ? this.valorCuota
                             : detallePlan.valorCuota;
          } else {
            this.cuotasRegaladas += 1;
          }
        }
        this.cuotaRegalada();
      }
      this.validarFrecuencia();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['frecuencia']){
      this.validarFrecuencia();
    }
  }

  cargarDetalles(planPagoEscogido: PlanDePagos) {
    this.opcionEscogida.emit(planPagoEscogido);
  }

  seleccionarPlanDepago(planDePago: PlanDePagos) {
    this.seleccionarDetalle.emit(planDePago.id);
  }
  private cuotaRegalada(){
    if (this.cuotasRegaladas === 1 ) {
      this.mensajeCuotaRegalada = `Aplica ${this.cuotasRegaladas} cuota regalada`;
    } else if (this.cuotasRegaladas > 1) {
      this.mensajeCuotaRegalada = `Aplica ${this.cuotasRegaladas} cuotas regaladas`;
    } else {
      this.mensajeCuotaRegalada = '';
    }
  }

  private validarFrecuencia(){
    
    if (this.planPago.numeroCuotas <= 1) {
      if(this.frecuencia === 15){
        this.mensajeCuota = 'cuota quincenal';
      }          
      else{
        this.mensajeCuota = 'cuota mensual';
      }        
    }else{
      if(this.frecuencia === 15){
        this.mensajeCuota = 'cuotas quincenales';
      }          
      else{
        this.mensajeCuota = 'cuotas mensuales';
      }      

    }

  }
}
