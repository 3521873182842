import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const idRastreo = sessionStorage.getItem('idRastreo');
    const identificadorSeguridad = sessionStorage.getItem('identificadorSeguridad');

    if (idRastreo && identificadorSeguridad) {
      const authReq = req.clone({
        headers: req.headers.set('idRastreo', idRastreo).set('identificadorSeguridad', identificadorSeguridad)
      });
      return next.handle(authReq);
    }

    return next.handle(req);
  }
}
