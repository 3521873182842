<ng-template #contenido let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <div class="row" *ngIf="modalBasico">
                <div class="col-md-12">
                    <div class="alerta-config">
                        <img src="{{icono}}" alt="">
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="modalBasico; else avisoLlamada">
                    <div class="col-md-12">
                        <div class="text-modal opensans text-center">
                            {{mensajesModal.mensaje}}
                        </div>
                    </div>
                </div>
                <ng-template #avisoLlamada>
                    <div class="d-flex justify-content-center">
                        <div class="card-agaval2">
                            <img class="img-card" src="../../../../../../../assets/img/ic-agaval-celverification.png" alt="">
                            <div class="numero-celular">
                                <strong class="numero-celular-titulo">Aviso importante</strong>
                                <div class="texto-cel opensans text-center">
                                    <span>{{mensajesModal.mensaje}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div [ngClass]="{'modal-footer': !centrarBotones , 'text-center acomodar-botones': centrarBotones}">
            <div *ngIf="visualizarBotonSecundario">
                <button [hidden]="!visualizarBotonSecundario" type="button" class="boton-secundario" data-dismiss="modal" [ngStyle]="(visualizarBotonSecundario) ? {'visibility': 'visible'} : {'visibility': 'hidden'}" (click)=" accionBoton(mensajesModal.tituloBotonSecundario)">
        {{mensajesModal.tituloBotonSecundario}}
      </button>
            </div>

            <button type="button" [ngClass]="{'boton-primario':true, 'subir-boton': centrarBotones}" class="" data-dismiss="modal" (click)="accionBoton(mensajesModal.tituloBotonPrimario)">
      {{mensajesModal.tituloBotonPrimario}}
    </button>
        </div>
    </div>
</ng-template>