import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-modal-contrato',
  templateUrl: './modal-contrato.component.html'
})
export class ModalContratoComponent {
  public myTemplate: any = '';
  contenidoContratoExterno = 'https://agavalceibadiag.blob.core.windows.net/templates/1.1%20Contrato%20Cliente%20Personal%20estandar.txt';
  @ViewChild('contenidoContrato', {static: false}) contenido: any;
  constructor(
    private  modalService: NgbModal,
    private config: NgbModalConfig,
    private http: HttpClient

    ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.http.get(this.contenidoContratoExterno, {responseType: 'text'}).subscribe(data => {
      this.myTemplate = data;
      });
    }

  abrirContrato() {
    this.modalService.open(this.contenido, {ariaLabelledBy: 'modal-basic-title' , centered: true, size: 'xl' });
  }

  cerrar() {
    this.modalService.dismissAll();
  }

  descargar() {
    window.open('https://agavalceibadiag.blob.core.windows.net/templates/Contrato.pdf', '_blank','noopener');
  }
}
