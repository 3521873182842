import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormatTimerPipe } from './pipes/formatTimer.pipe';
import { InputNumerosDirective } from './directives/input-solo-numeros.directive';
import { TotalValidatePipe } from './pipes/total-validate.pipe';

@NgModule({
  declarations: [
    FormatTimerPipe,
    InputNumerosDirective,
    TotalValidatePipe
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    FormatTimerPipe,
    InputNumerosDirective,
    TotalValidatePipe
  ]
})
export class SharedModule { }
