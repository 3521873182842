<div *ngIf = "mostrarPantalla" class="container">
    <div class="off-canvas">
        <div class="contenedor-canvas">
            <div class="d-flex justify-content-between">
                <h1 class="encabezado">Plan a {{planPago?.numeroCuotas}} cuotas</h1>
                <div  class="close" >
                    <a class="cerrar-detalle" a href="javascript:void(0)" (click)="cerraDetalle()" >
                        <img class="ancho-img" src="../../../../../assets/img/ic-agaval-close.svg" alt="">
                    </a>
                </div>
            </div>
            <div class="contenido-canvas">
                <h2 class="subtitulo margin-title">Detalle de los costos asociados</h2>
                <div class="detalle-costos">
                    <div class="detalle-item">
                        <span>Compra</span>
                        <h3>${{planPago.totalCapital | number }}</h3>
                    </div>
                    <div class="detalle-item">
                        <span>Intereses</span>
                        <h3>${{planPago.totalIntereses | number }}</h3>
                    </div>
                    <div class="detalle-item">
                        <span>IVA Intereses</span>
                        <h3>${{planPago.totalInteresesIva | number }}</h3>
                    </div>
                    <div class="detalle-item">
                        <span>Fianza</span>
                        <h3>${{planPago.totalFianza | number }}</h3>
                    </div>
                    <div class="detalle-item" *ngIf="planPago.totalValorSeguro | totalValidate">
                        <span>Seguro</span>
                        <h3>${{planPago.totalValorSeguro | number }}</h3>
                    </div>
                    <div class="total-item">
                        <span>Total</span>
                        <h3>${{planPago.totalItems | number }}</h3>
                    </div>
                </div>
                <h2 class="subtitulo margin-title">Detalle de las cuotas</h2>
                <div *ngFor="let detalle of planPago.detallesPlanDePago">
                    <app-detalle-pago [detallePago]="detalle"></app-detalle-pago>
                </div>
            </div>
        </div>
    </div>
    
    <div class="backdrop"></div>    
</div>
