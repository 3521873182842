import { Component, OnInit, ViewChild } from '@angular/core';
import { DetallePlanDePago } from '../shared/models/detallePlanDePago';
import { PagoCreditoService } from '../shared/services/pago-credito.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Sesion } from 'src/app/core/models/sesion';
import { RespuestaIniciarFirma } from '../shared/models/respuestaIniciarFirma';
import { ResultadoProceso } from '../shared/models/resultadoProceso';
import { AlertasService } from 'src/app/core/services/Alertas.service';
import { MensajeModalService } from 'src/app/core/services/mensaje-modal.service';
import { OpcionPasarela } from '../pasarela/components/models/enumOpcionPasarela';
import { TipoMensajeModal } from 'src/app/core/models/enumTipoMensajeModal';
import { MensajeService } from 'src/app/feature/tu-credito-agaval/components/shared/services/mensaje.service';
import { CodigoFinalizacion } from '../shared/models/enumCodigoFinalizacion';
import { ModalContratoComponent } from './modal-contrato/modal-contrato.component';
import { Pasarela } from '../pasarela/components/models/pasarela';
import { PasarelaService } from '../shared/services/pasarela.service';
import { DetallePlanPagoComponent } from '../detalle-plan-pago/detalle-plan-pago.component';
import { CancelarPago } from '../shared/models/enumCancelarPago';
import { TipoAlerta } from 'src/app/core/models/enumTipoAlerta';
import { Mensaje } from '../shared/models/mensaje';
import * as _ from 'lodash';
import { ResultadoCancelacionProceso } from '../shared/models/resultadoCancelacionProceso';
import { CargandoService } from 'src/app/core/services/cargando.service';
import { CancelarProceso } from '../shared/models/cancelarProceso';
import { PlanDePagos } from 'src/app/feature/tu-credito-agaval/components/shared/models/planDePagos';

@Component({
  selector: 'app-planes-pago',
  templateUrl: './planes-pago.component.html',
  styleUrls: ['./planes-pago.component.sass']
})
export class PlanesPagoComponent implements OnInit {
  @ViewChild('contrato', { static: false }) contratoComponente: ModalContratoComponent;
  @ViewChild('detallePago', { static: false }) detalleComponente: DetallePlanPagoComponent;

  mostrarPantalla = false;
  detalles: Array<DetallePlanDePago>;
  planesDePago: Array<PlanDePagos>;
  listaPlanPagos: Array<PlanDePagos>;
  planPagoSeleccionado: PlanDePagos;
  sesion: Sesion;
  deshabilitarFirma = false;
  desHabilitarCancelar = false;
  permitirFirma = true;
  aceptaContrato = false;
  verTodosActivado = false;
  verTodosTexto = 'Ver todas las cuotas';
  datos: RespuestaIniciarFirma;
  public visibilizarDetalle = false;
  public SomeIcon = '../../../assets/img/ic-agaval-bulb.svg';
  public Description = 'Seleccione el plan de pagos que más se ajuste a su presupuesto en un plazo máximo de 9 cuotas mensuales.';
  frecuencia: number;

  constructor(
    private servicioPagoCredito: PagoCreditoService,
    private servicioSesion: SesionService,
    private servicioAlerta: AlertasService,
    private servicioMensajesModal: MensajeModalService,
    private servicioMensaje: MensajeService,
    private servicioPasarela: PasarelaService,
    private cargandoService: CargandoService
  ) {
    this.planPagoSeleccionado = null;
    this.visibilizarDetalle = false;
    this.sesion = this.servicioSesion.obtenerSesionActual();

  }

  ngOnInit() {
    this.servicioMensajesModal.ListaPlanDePago.subscribe(resultado => {
      this.mostrarPantalla = true;
      if (resultado === 'SI') {
        this.cancelarProceso(CodigoFinalizacion.SeleccionCuota);
      } else if (resultado === 'Entiendo') {
        this.procesarRespuestaDeFirma(this.datos);
      } else if (resultado === 'No tengo el celular') {
        this.cancelarProceso(CodigoFinalizacion.ValidacionCelular);
      } else {
        this.desHabilitarCancelar = false;
        this.deshabilitarFirma = false;
      }
    });
  }

  llenarPlanDepagos(planesDePago: Array<PlanDePagos>) {
    this.sesion = this.servicioSesion.obtenerSesionActual();
    this.servicioPagoCredito.cargarMensajePlanDPagos(this.sesion.conexionId).subscribe((resultado: ResultadoProceso) => {
      this.Description = resultado.mensaje;
      this.servicioPasarela.cambiarNombre(resultado.nombreCliente);     
      this.frecuencia = resultado.frecuencia;
    });
    this.listaPlanPagos = planesDePago;
    this.planesDePago = planesDePago;
    const indice = this.planesDePago.findIndex(x => x.preferida);
    this.planPagoSeleccionado = this.planesDePago[indice];
    this.filtrar(this.verTodosActivado);
  }

  filtrar(verTodosActivado: boolean) {
    this.listaPlanPagos = _.orderBy(this.planesDePago, ['numeroCuotas'], ['asc']);
    if (!verTodosActivado && this.verTodosTexto !== '') {
      this.verTodosTexto = 'Ver todas las cuotas';
      if (this.planesDePago.filter(x => x.sugerida === 2).length > 0) {
        this.listaPlanPagos = this.planesDePago.filter(x => x.sugerida === 2)
          .sort((a, b) => b.sugerida - a.sugerida ||
            a.numeroCuotas - b.numeroCuotas);
      } else {
        this.listaPlanPagos = this.planesDePago.filter(x => x.sugerida === 1)
          .sort((a, b) => b.sugerida - a.sugerida ||
            a.numeroCuotas - b.numeroCuotas);
      }
    } else {
      this.verTodosTexto = '';
    }

  }

  verTodosClick() {
    this.verTodosActivado = !this.verTodosActivado;
    this.filtrar(this.verTodosActivado);
  }

  mostrarDetalle(planPagoEscogido: PlanDePagos) {
    this.visibilizarDetalle = true;
    this.detalleComponente.iniciarPantalla(planPagoEscogido);
  }

  ocultarDetalle(ocultar: boolean) {
    this.visibilizarDetalle = ocultar;
  }

  seleccionarPlanPago(idPlanPago: string) {
    this.visibilizarDetalle = false;
    const indiceActual = this.planesDePago.findIndex(plan => plan.preferida);
    this.planesDePago[indiceActual].preferida = false;
    const indice = this.planesDePago.findIndex(plan => plan.id === idPlanPago);
    this.planesDePago[indice].preferida = true;
    this.planPagoSeleccionado = this.planesDePago[indice];
    this.habilitarFirma();
    this.llenarPlanDepagos(this.planesDePago);
  }

  iniciarFirma() {
    this.deshabilitarFirma = true;
    if (this.permitirFirma) {
      if (!this.aceptaContrato) {
        this.servicioMensaje.consulta('NOTIFICACION_DEBE_ACEPTAR_CONTRATO').subscribe(resultado => {
          this.generarMensajeModal(resultado, true);
        }
        );
      }

    } else {
      this.mostrarPantalla = false;
      this.cargandoService.cambiarEstadoCargando(true);
      this.sesion = this.servicioSesion.obtenerSesionActual();
      this.servicioPagoCredito.crearFirma(
        this.sesion.conexionId, this.planPagoSeleccionado.numeroCuotas).subscribe(resultado => {
          this.datos = resultado;
          this.generarAvisoCelularModal(this.datos.celular);
        });
    }
  }

  procesarRespuestaDeFirma(resultado: RespuestaIniciarFirma) {
    const opcionPasarela: Pasarela = { opcion: OpcionPasarela.solicitudLlamada, data: resultado };
    this.servicioPasarela.abrirPasarela(opcionPasarela);
    this.cargandoService.cambiarEstadoCargando(false);
  }

  generarMensajeCancelacion() {
    this.desHabilitarCancelar = true;
    this.servicioMensaje.consulta('NOTIFICACION_CANCELAR_PAGO').subscribe(resultado => {
      this.generarMensajeModal(resultado, false);
    }
    );
  }

  generarMensajeModal(resultado: Mensaje, modalValidacionDatos: boolean) {
    if (modalValidacionDatos) {
      this.servicioMensajesModal.mostratrModalUnaOpcion(resultado.descripcion, TipoMensajeModal.cancelacion, 'Entendido',
        OpcionPasarela.planDePagos, TipoAlerta.Advertencia);
    } else {
      this.servicioMensajesModal.mostratrModalMultiopcion(resultado.descripcion, TipoMensajeModal.confirmacion, 'SI', 'NO',
        OpcionPasarela.planDePagos, TipoAlerta.Informativa);
    }
  }

  generarAvisoCelularModal(celular: string) {
    const nuevoMensaje = celular;
    this.servicioMensajesModal.mostratrModalAvisoCelular(nuevoMensaje, TipoMensajeModal.confirmacion, 'Entiendo', 'No tengo el celular',
      OpcionPasarela.planDePagos, TipoAlerta.Informativa);
  }

  generarAlertaCancelado(resultado: ResultadoCancelacionProceso) {
    this.servicioAlerta.completado(resultado.mensaje);
    if (this.servicioMensajesModal.inIframe()) {
      window.parent.postMessage('RetornoNegativo', '*');
    } else {
      window.location.href = resultado.urlRetornoNegativo;
    }
  }

  cancelarProceso(codigo: number) {
    this.cargandoService.cambiarEstadoCargando(true);
    const cancelar: CancelarProceso = {
      idrastreo: this.sesion.conexionId,
      idorden: '',
      idproceso: CancelarPago.Liquidador,
      codigoFinalizacion: codigo
    };
    this.servicioPagoCredito.cancelarProcesoPago(cancelar).subscribe(resultado => {
      this.generarAlertaCancelado(resultado);
    });
  }

  seleccionarPreferida(planPagos: PlanDePagos) {
    return planPagos.preferida ? 'selected' : '';
  }

  aceptarContrato(event) {
    this.aceptaContrato = event.target.checked;
    this.habilitarFirma();
  }

  abrirContrato() {
    this.contratoComponente.abrirContrato();
  }

  habilitarFirma() {
    this.permitirFirma = !(this.planPagoSeleccionado && this.aceptaContrato);
  }
}
