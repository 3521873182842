<div class="modal-header">
    <h4 class="modal-title titulo-modal">
      AMPLIACIÓN DE CUPO
    </h4>
  </div>
  <div class="modal-body">
    <div class="ayuda-contextual">
      <img src="/assets/img/ic-agaval-bulb.svg" alt="ayuda-contextual">
      <p class="textomini opensans">{{mensaje}}</p>
  </div>  
    
      <div class="progress mt-1">
        <div class="progress-bar" role="progressbar" [style.width]="progressbarValue+'%'" [attr.aria-valuenow]="progressbarValue" aria-valuemin="0" aria-valuemax="100">
          <label [ngClass]="{'label-done': contador === 0 }">{{this.contador | formatTimer}}</label>
        </div>
    </div>
  </div>