export enum OpcionPasarela {
  Home,
  validacionCliente,
  planDePagos,
  solicitudLlamada,
  ValidacionCelular,
  Error,
  RedireccionVinculacion,
  iniciarPago,
  RedireccionActualizacion
}
