<div class="detalle-cuota">
  <div class="cuota">
      <div class="numero-cuota">
          <span>Cuota {{detallePago.numeroCuota}}</span>
          <strong>{{detallePago.fechaPago | date}}</strong>
      </div>
      <div class="precio-cuota">
          <h3>${{detallePago.valorCuota| number }}</h3>
      </div>
  </div>
</div>
