import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { environment } from 'src/environments/environment';
import { SolicitudFirmaOtp } from '../models/solicitudFirmaOtp';

@Injectable({
  providedIn: 'root'
})
export class FirmaOtpService extends BaseService {

  constructor(protected http: HttpClient) { super(http); }

  public generarOtp( solicitudFirmaOtp: SolicitudFirmaOtp) {
    const endpont = `${environment.pagoCreditoDigitalEndPoint}FirmaOtp/GenerarOtp`;
    return this.doPost<SolicitudFirmaOtp, any>(endpont, solicitudFirmaOtp, this.optsName('Generar otp firma'));
  }

}
