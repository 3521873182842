import { Component, OnInit } from '@angular/core';
import { Alerta } from '../../models/alerta';
import { AlertasService } from '../../services/Alertas.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { TipoAlerta } from '../../models/enumTipoAlerta';

@Component({
  selector: 'app-alerta',
  templateUrl: './alerta.component.html'
})
export class AlertaComponent implements OnInit {
  mensaje: string;
  subscription: Subscription;
  alertas: Alerta[] = [];
  constructor(private alertasService: AlertasService) {
  }

  cerrar(): void {
    this.subscription.unsubscribe();
    this.alertasService.clear();
  }

  ngOnInit() {
    this.subscription = this.alertasService.getAlert().subscribe((alerta: Alerta) => {
      if (!alerta) {
          this.alertas = [];
          return;
      }

      // add alert to array
      this.alertas.push(alerta);
  });
  }

  removeAlerta(alerta: Alerta) {
    this.alertas = this.alertas.filter(x => x !== alerta);
  }

  cssClass(alert: Alerta) {
    if (!alert) {
        return;
    }

    // return css class based on alert type
    switch (alert.tipoAlerta) {
        case TipoAlerta.Completado:
            return 'alert alert-success';
        case TipoAlerta.Error:
            return 'alert alert-danger';
        case TipoAlerta.Informativa:
            return 'alert alert-info';
        case TipoAlerta.Advertencia:
            return 'alert alert-warning';
    }
   }
}
