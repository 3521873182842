import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MensajeModal } from '../../../../../../core/models/mensajeModal';
import { MensajeModalService } from '../../../../../../core/services/mensaje-modal.service';
import { OpcionPasarela } from 'src/app/feature/tu-credito-agaval/components/pasarela/components/models/enumOpcionPasarela';
import { TipoMensajeModal } from '../../../../../../core/models/enumTipoMensajeModal';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TipoAlerta } from '../../../../../../core/models/enumTipoAlerta';
import { RedireccionVinculacionService } from '../../services/redireccion-vinculacion.service';
import { RedireccionActualizacionService } from '../../services/redireccion-actualizacion.service';
import { ParametrosService } from '../../services/parametros.service';
import { parametros } from '../../parametros/parametros';

@Component({
  selector: 'app-mensaje-modal',
  templateUrl: './mensaje-modal.component.html',
  styleUrls: ['./mensaje-modal.component.sass']
})
export class MensajeModalComponent implements OnInit {
  subscription: Subscription;
  mensajesModal: MensajeModal;
  centrarBotones = false;
  visualizarBotonSecundario = true;
  modalBasico = true;
  icono = '../../../assets/img/ic-agaval-bulb.svg';
  @ViewChild('contenido', {static: false}) contenido: any;
  constructor(
    private mensajeModalService: MensajeModalService,
    private  modalService: NgbModal,
    private config: NgbModalConfig,
    private servicioRedireccionVinculacion: RedireccionVinculacionService,
    private servicioRefireccionActualizacion: RedireccionActualizacionService,
    private servicioParametros: ParametrosService
    ) {
    this.centrarBotones = false;
    this.mensajesModal = new MensajeModal();
    config.backdrop = 'static';
    config.keyboard = false;
   }

  ngOnInit() {
    this.mensajeModalService.ventanaModal.subscribe((modal) => {
    if (!modal) {
        this.mensajesModal = new MensajeModal();
        return;
    }
    this.mensajesModal = modal;
    this.iniciarModal();
  });
  }

  iniciarModal() {
    this.modalBasico = this.mensajesModal.basico;
    this.centrarBotones = false;
    if (this.mensajesModal.tipoModal === TipoMensajeModal.cancelacion) {
        this.centrarBotones = true;
    }
    switch (this.mensajesModal.tipoMensajeAlerta) {
      case TipoAlerta.Advertencia:
        this.icono = '../../../assets/img/ic-alert.svg';
        break;
      case TipoAlerta.Error:
        this.icono = '../../../assets/img/ic-error.svg';
        break;
      case TipoAlerta.Informativa:
          this.icono = '../../../assets/img/ic-info.svg';
          break;
      case TipoAlerta.Completado:
        this.icono = '../../../assets/img/ic-success.svg';
        break;
    }

    this.visualizarBotonSecundario = this.mensajesModal.tituloBotonSecundario !== '';
    this.modalService.open(this.contenido, {ariaLabelledBy: 'modal-basic-title' , centered: true });
  }

  removerModal() {
    this.mensajesModal = new MensajeModal();
  }

  accionBoton(accion: string) {
    switch ( this.mensajesModal.opcion) {
      case OpcionPasarela.validacionCliente: {
        this.mensajeModalService.responderValidacionCliente(accion);
        break;
      }
      case OpcionPasarela.planDePagos: {
        this.mensajeModalService.responderPlanDePago(accion);
        break;
      }
      case OpcionPasarela.solicitudLlamada: {
        this.mensajeModalService.responderSolicidudDeLlamada(accion);
        break;
      }
      case OpcionPasarela.ValidacionCelular: {
        this.mensajeModalService.responderValidacionCelular(accion);
        break;
      }
      case OpcionPasarela.Home: {
        if (this.mensajeModalService.inIframe()) {
          window.parent.postMessage('RetornoNegativo', '*');
        }
        this.mensajeModalService.responderHome(accion);
        break;
      }
      case OpcionPasarela.Error: {
        if (this.mensajeModalService.inIframe()) {
          window.parent.postMessage('RetornoNegativo', '*');
        } else {
          window.location.href = this.mensajesModal.urlRetorno;
        }
        break;
      }
      case OpcionPasarela.RedireccionVinculacion: {
        this.servicioParametros.consulta(parametros.urlVinculacion).subscribe(url => {
          this.servicioRedireccionVinculacion.redireccionarVinculacion(this.mensajesModal.data, url.valor);
        });
        break;
      }
      case OpcionPasarela.iniciarPago: {
        this.mensajeModalService.responderIniciarPago(accion);
        break;
      }
      case OpcionPasarela.RedireccionActualizacion: {
        this.servicioRefireccionActualizacion.redireccionActualizacion(this.mensajesModal.data);
        break;
      }
    }
    this.modalService.dismissAll();
  }
}
