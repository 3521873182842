import { Component, OnInit, ViewChild } from '@angular/core';
import { TipoMensajeModal } from 'src/app/core/models/enumTipoMensajeModal';
import { MensajeModalService } from 'src/app/core/services/mensaje-modal.service';
import { ValidacionCelularComponent } from '../validacion-celular/validacion-celular.component';
import { AlertasService } from 'src/app/core/services/Alertas.service';
import { OpcionPasarela } from '../pasarela/components/models/enumOpcionPasarela';
import { Mensaje } from '../shared/models/mensaje';
import { CargandoService } from 'src/app/core/services/cargando.service';

@Component({
  selector: 'app-validar-usuario',
  templateUrl: './validar-usuario.component.html'
})
export class ValidarUsuarioComponent implements OnInit {
  mensaje: Mensaje;
  mostrarPantalla = true;
  @ViewChild('validacioncelular', {static: false}) validacionCelularComponent: ValidacionCelularComponent;
  constructor(
    private modalService: MensajeModalService,
    private alertasService: AlertasService,
    private cargandoService: CargandoService ) {
  }

  ngOnInit() {
    this.modalService.ValidacionCliente.subscribe(resultado => {
      this.obtenerResultadoModal(resultado);
       });
  }
  ocultarPantallasInternas() {
    this.validacionCelularComponent.mostrarPantalla = false;
  }
  clasificarMensaje(mensaje: Mensaje) {
    this.mensaje = mensaje;
    this.validacionCelularComponent.mostrarPantalla = mensaje.tipoMensaje === 1;
    if (!this.validacionCelularComponent.mostrarPantalla) {
      this.generarMensaje();
    } else {
      this.validacionCelularComponent.asignarMensaje(mensaje);
    }
  }

  generarMensaje() {
    this.modalService.mostratrModalUnaOpcion(this.mensaje.descripcion, TipoMensajeModal.cancelacion, 'Aceptar',
      OpcionPasarela.validacionCliente, this.mensaje.tipoMensaje);
  }

  sleep(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
  }

  public obtenerResultadoModal(resultado: string) {
    if (resultado === 'Aceptar') {
      this.mostrarPantalla = false;
      this.cargandoService.cambiarEstadoCargando(true);
      window.location.href = this.mensaje.linkRetorno;
     } else {
      this.alertasService.error('Error en el funcionamiento del modal');
     }
  }

}
