import { Component, Output, EventEmitter } from '@angular/core';
import { PlanDePagos } from 'src/app/feature/tu-credito-agaval/components/shared/models/planDePagos';

@Component({
  selector: 'app-detalle-plan-pago',
  templateUrl: './detalle-plan-pago.component.html'
})
export class DetallePlanPagoComponent {
  @Output() ocultarDetalle = new EventEmitter<boolean>();
  planPago: PlanDePagos;
  mostrarPantalla = false;

  iniciarPantalla(planPago: PlanDePagos) {
    this.planPago = new PlanDePagos();
    this.mostrarPantalla = true;
    this.planPago = planPago;
  }
  cerraDetalle() {
    this.mostrarPantalla = false;
    this.ocultarDetalle.emit(false);
  }
}
