import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './feature/home/home.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: ':IdOrden/:Comercio',
   component: HomeComponent,
   children: [
    { path: '', loadChildren: () => import('./feature/tu-credito-agaval/tu-credito-agaval.module').then(mod => mod.TuCreditoAgavalModule)},
   ]
  },
  { path: ':IdOrden',
   component: HomeComponent,
   children: [
    { path: '', loadChildren: () => import('./feature/tu-credito-agaval/tu-credito-agaval.module').then(mod => mod.TuCreditoAgavalModule)},
   ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
