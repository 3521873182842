<div *ngIf="mostrarPantalla">
    <div class="container">
        <app-temporizacion #temporizador (mostrarTimeOut)="MostrarTimeOut($event)"></app-temporizacion>
        <app-solicitud-otp #solicitudOtp></app-solicitud-otp>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <div class="card-agaval">
                        <h3>
                            {{datos?.titulo}}
                        </h3>
                        <app-ayuda-contextual [icono]="SomeIcon" [descripcion]="descripcion">
                        </app-ayuda-contextual>
                        <div>
                            <label class="number">Pronuncie estos números en la llamada</label>
                        </div>
                        <div class="llamada-firma my-2">
                            <div class="texto-cel">
                                <span>{{datos?.otpTexto}}</span>
                            </div>
                        </div>

                        <div *ngIf="false" class="resend">
                            <p class="texto-llamada">¿No recibiste la llamada?</p>
                            <a class="boton-link opensans" href="javascript:void(0)"><img src="../../../../../assets/img/ic-info.svg" alt="">Solicitar llamada</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="cont-bottom">
            <section class="footer-agaval">
                <div class="d-flex justify-content-center">
                    <button class="boton-secundario ml-3" [disabled]="deshabilitarCancelar" (click)="cancelar() ">Cancelar</button>
                    <button class="boton-primario ml-3" [disabled]="deshabilitarLlamada" (click)="llamar()">{{botonLlamar}}</button>
                </div>
            </section>
        </div>
    </div>
</div>