import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/core/services/base.service';
import { SolicitudValidacionCodigoOtp } from '../model/solicitudValidacionCodigoOtp';
import { RespuestaValidarCodigoOtp } from '../model/respuestaValidarCodigoOtp';

@Injectable({
  providedIn: 'root'
})
export class ValidacionIdentidadService extends BaseService {

  constructor(protected http: HttpClient) { super(http); }

  public validarOtp(solicitudValidacionCodigoOtp: SolicitudValidacionCodigoOtp) {
    const endPoint = `${environment.pagoCreditoDigitalEndPoint}FirmaOtp/ValidarOtp`;
    return this.doPost<SolicitudValidacionCodigoOtp, RespuestaValidarCodigoOtp>(endPoint,
      solicitudValidacionCodigoOtp,
      this.optsName('Validar otp'));
  }

}
