import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-seleccion-tipo-credito',
  templateUrl: './seleccion-tipo-credito.component.html',
  styleUrls: ['./seleccion-tipo-credito.component.css']
})
export class SeleccionTipoCreditoComponent {
@Input() nombre: string;
  tipoCredito:number;
  public SomeIcon = '../../../assets/img/ic-agaval-bulb.svg';
  public Description = 'Para continuar con tu compra selecciona el tipo de credito';

  constructor(public activeModal: NgbActiveModal) {}

  seleccionar(tipo: number) {
    this.activeModal.close({tipo: tipo, nombre: this.nombre});
  }

}
