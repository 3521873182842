import { Injectable, Output, EventEmitter } from '@angular/core';
import { MensajeOtp } from 'src/app/feature/tu-credito-agaval/components/shared/models/mensajeOtp';

@Injectable({
  providedIn: 'root'
})
export class CargandoService {
  @Output() CambioEstadoCargadnoEmitter: EventEmitter<any> = new EventEmitter();
  @Output() CambioEstadoCargandoLlamadaEmitter: EventEmitter<any> = new EventEmitter();
  @Output() ocultarCargando: EventEmitter<any> = new EventEmitter();

cambiarEstadoCargando(estado: boolean) {
  this.CambioEstadoCargadnoEmitter.emit(estado);
}

cambiarEstadoCargandoLlamada(mensajeOtp: MensajeOtp) {
  this.CambioEstadoCargandoLlamadaEmitter.emit(mensajeOtp);
}

}
