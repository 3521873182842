export enum CodigoFinalizacion {
    ninguno,
    ValidacionCelular,
    Habilitado,
    CupoInsuficiente,
    SeleccionCuota,
    Llamada,
    TimeOut,
    NoExistencia
}
