<ng-template #contenidoContrato let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            CONDICIONES GENERALES PARA LA ADQUISICIÓN DE BIENES
                                BAJO
                                SISTEMAS DE FINANCIACIÓN
        </h4>
    </div>
    <div class="modal-body contrato-modal">
            
        <div class="modal-content">
            <div class="modal-body contrato-modal">
                    
                <section>
                    <div class="containerContrato" style='overflow:auto; width:100%;height:400px;'>
                            <div [innerHtml]="myTemplate"></div> 
                    </div>
                </section>
            </div>
            <div class="text-center footer-modal">
                <button type="button" class="btn boton-secundario" id="btn-cerrar-modal-contrato" data-dismiss="modal" (click)="cerrar()">Cerrar</button>
                <button type="button" class="btn boton-primario ml-3" data-dismiss="modal" (click)="descargar()"> Descargar
                </button>
            </div>
        </div>
    </div>
</ng-template>