import { Injectable } from '@angular/core';
import { Sesion } from '../models/sesion';

@Injectable({
  providedIn: 'root'
})
export class SesionService {
  private esUnaNuevaSesion;
  public sesion: Sesion;
constructor() {
  this.esUnaNuevaSesion = false;
 }
 iniciarSesion(sesion: Sesion) {
  this.esUnaNuevaSesion = true;
  this.sesion = sesion;
  localStorage.setItem('SesionActual', JSON.stringify(sesion));
}

obtenerSesionActual() {
  return JSON.parse(localStorage.getItem('SesionActual'));
}
}
