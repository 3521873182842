import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Mensaje } from '../models/mensaje';

@Injectable({
  providedIn: 'root'
})
export class MensajeService extends BaseService {

  constructor(protected http: HttpClient) { super(http); }

  public consulta( nombreMensaje: string) {
    const endpont = `${environment.pagoCreditoDigitalEndPoint}MensajesRespuestaUsuario/${nombreMensaje}`;
    return this.doGet<Mensaje>(endpont, this.optsName('consultar mensajes'));
  }

}
