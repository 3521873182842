import { Component } from '@angular/core';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.sass']
})
export class CargandoComponent {
  activarCargando = false;

  cambiarEstadoCargando(activarCargando: boolean) {
    this.activarCargando = activarCargando;
  }
}
