import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTimer'
})
export class FormatTimerPipe implements PipeTransform {

  transform(value: number): string {
    const segundos = 60;
    const slice = -2;
    const minutes: number = Math.floor(value / segundos);
    return ('00' + minutes).slice(slice) + ':' + ('00' + Math.floor(value - minutes * segundos)).slice(slice);
  }

}
