<div *ngIf="activarCargando" class="cont-load">
        <div class="overlay"></div>    
        <div id="container">
            <strong class="number">Recita estos números en la llamada</strong>
            <div class="llamada-firma my-2">
                <div class="texto-cel">
                    <div class="otp">{{ otpTexto }}</div>
                </div>
            </div>
            <p class="info-important opensans">No cierres o recargues la página hasta finalizar el proceso.</p>
            <div class="load d-flex justify-content-evenly">
                <span class="sp1"><span class="sp2"><span class="sp3"></span></span></span>
                <div class="logo-agaval"><img src="./../../../../../../../assets/img/preload-agaval.svg" alt=""></div>
            </div>
        </div>
    </div>
    