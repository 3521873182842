import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PasarelaComponent } from './components/pasarela/pasarela.component';
import { ValidacionCelularComponent } from './components/validacion-celular/validacion-celular.component';
import { PlanesPagoComponent } from './components/planes-pago/planes-pago.component';
import { ValidarUsuarioComponent } from './components/validar-usuario/validar-usuario.component';


const routes: Routes = [
  {
    path: '',
    component: PasarelaComponent,
    children: [
      {
        path: 'plandepagos',
        component: PlanesPagoComponent
      },
      {
        path: 'validarusuario',
        component: ValidarUsuarioComponent,
        children: [
          {
            path: 'validaciondecelular',
            component: ValidacionCelularComponent
          }
        ]
      }
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TuCreditoAgavalRoutingModule { }
