import { Component } from '@angular/core';
import { MensajeOtp } from '../../models/mensajeOtp';

@Component({
  selector: 'app-cargando-llamada',
  templateUrl: './cargando-llamada.component.html',
  styleUrls: ['./cargando-llamada.component.sass']
})
export class CargandoLlamadaComponent {
  activarCargando = false;
  otpTexto: string;

  cambiarEstadoCargando(mensajeOtp: MensajeOtp) {
    this.otpTexto = mensajeOtp.mensaje;
    this.activarCargando = mensajeOtp.estado;
  }
}
