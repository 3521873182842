import { TipoAlerta } from './enumTipoAlerta';

export class Alerta {
    mensaje: string;
    tipoAlerta: TipoAlerta;
    keepAfterRouteChange: boolean;

    constructor(init?: Partial<Alerta>) {
        Object.assign(this, init);
    }
}
