import { Injectable, Output, EventEmitter } from '@angular/core';
import { Pasarela } from '../../pasarela/components/models/pasarela';
import { PlanDePagos } from '../models/planDePagos';
import { Mensaje } from '../models/mensaje';
import { OpcionPasarela } from '../../pasarela/components/models/enumOpcionPasarela';
import { RespuestaEstadoFirmaAUsuario } from '../models/respuestaEstadoFirmaAUsuario';

@Injectable({
  providedIn: 'root'
})
export class PasarelaService {
 @Output() pasarela: EventEmitter<Pasarela> = new EventEmitter();
 @Output() cargando: EventEmitter<OpcionPasarela> = new EventEmitter();
 @Output() PlanDePago: EventEmitter<Array<PlanDePagos>> = new EventEmitter();
 @Output() ValidacionCelular: EventEmitter<Mensaje> = new EventEmitter();
 @Output() ValidacionCliente: EventEmitter<Mensaje> = new EventEmitter();
 @Output() SolicitudLlamada: EventEmitter<RespuestaEstadoFirmaAUsuario> = new EventEmitter();
 @Output() nombreCliente: EventEmitter<string> = new EventEmitter();

cambiarNombre(nombreCliente: string) {
  this.nombreCliente.emit(nombreCliente);
}

abrirCarganDoPasarela(opcion: OpcionPasarela) {
  this.cargando.emit(opcion);
}

abrirPasarela(pasarela: Pasarela) {
  this.pasarela.emit(pasarela);
}

abrirPlanDepagos(datos: Array<PlanDePagos>) {
  this.PlanDePago.emit(datos);
}

abrirValidacionCelular(datos: Mensaje) {
  this.ValidacionCelular.emit(datos);
}

abrirValidacionCliente(datos: Mensaje) {
  this.ValidacionCliente.emit(datos);
}

abrirSolicitudLlamada(datos: RespuestaEstadoFirmaAUsuario) {
  this.SolicitudLlamada.emit(datos);
}
}
