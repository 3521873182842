import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TuCreditoAgavalRoutingModule } from './tu-credito-agaval-routing.module';
import { PasarelaComponent } from './components/pasarela/pasarela.component';
import { DetallePlanPagoComponent } from './components/detalle-plan-pago/detalle-plan-pago.component';
import { ValidacionCelularComponent } from './components/validacion-celular/validacion-celular.component';
import { PlanesPagoComponent } from './components/planes-pago/planes-pago.component';
import { PlanPagoComponent } from './components/planes-pago/plan-pago/plan-pago.component';
import { AyudaContextualComponent } from './components/shared/components/ayuda-contextual/ayuda-contextual.component';
import { ValidarUsuarioComponent } from './components/validar-usuario/validar-usuario.component';
import { DetallePagoComponent } from './components/detalle-plan-pago/detalle-pago/detalle-pago.component';
import { ModalContratoComponent } from './components/planes-pago/modal-contrato/modal-contrato.component';
import { SolicitudLlamadaComponent } from './components/solicitud-llamada/solicitud-llamada.component';
import { MensajeModalComponent } from './components/shared/components/mensaje-modal/mensaje-modal.component';
import { TemporizacionComponent } from './components/shared/components/temporizacion/temporizacion.component';
import { AmpliacionCupoComponent } from './components/ampliacion-cupo/ampliacion-cupo.component';
import {ModalOtpComponent} from './components/modal-otp/modal-otp.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SolicitudOtpComponent } from './components/solicitud-otp/solicitud-otp.component';
import { SeleccionTipoCreditoComponent } from './components/seleccion-tipo-credito/seleccion-tipo-credito.component';



@NgModule({
    declarations: [
        PasarelaComponent,
        DetallePlanPagoComponent,
        ValidacionCelularComponent,
        PlanesPagoComponent,
        PlanPagoComponent,
        AyudaContextualComponent,
        ValidarUsuarioComponent,
        DetallePagoComponent,
        ModalContratoComponent,
        SolicitudLlamadaComponent,
        MensajeModalComponent,
        AmpliacionCupoComponent,
        TemporizacionComponent,
        SolicitudOtpComponent,
        ModalOtpComponent,
        SeleccionTipoCreditoComponent
    ],
    imports: [
        CommonModule,
        TuCreditoAgavalRoutingModule,
        SharedModule,
        ReactiveFormsModule
    ],
    exports: [PasarelaComponent, AmpliacionCupoComponent]
})
export class TuCreditoAgavalModule { }
