import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ayuda-contextual',
  templateUrl: './ayuda-contextual.component.html'
})
export class AyudaContextualComponent {
  @Input() icono: string ;
  @Input() descripcion: string ;
}
