import { Component, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TipoAlerta } from 'src/app/core/models/enumTipoAlerta';
import { TipoMensajeModal } from 'src/app/core/models/enumTipoMensajeModal';
import { Sesion } from 'src/app/core/models/sesion';
import { parameter } from 'src/app/core/parameter/parameter';
import { CargandoService } from 'src/app/core/services/cargando.service';
import { MensajeModalService } from 'src/app/core/services/mensaje-modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { ModalOtpComponent } from '../modal-otp/modal-otp.component';
import { OpcionPasarela } from '../pasarela/components/models/enumOpcionPasarela';
import { MensajeOtp } from '../shared/models/mensajeOtp';
import { SolicitudFirmaOtp } from '../shared/models/solicitudFirmaOtp';
import { FirmaOtpService } from '../shared/services/firma-otp.service';

@Component({
  selector: 'app-solicitud-otp',
  templateUrl: './solicitud-otp.component.html',
  styleUrls: ['./solicitud-otp.component.sass']
})
export class SolicitudOtpComponent {

  @ViewChild('contenidoSolicitudOtp', {static: false}) contenidoSolicitudOtp: any;
  @ViewChild('otp', {static: false}) modalOtpComponent: ModalOtpComponent;

  constructor(private  modalService: NgbModal,
              private firmaOtpService: FirmaOtpService,
              private cargandoService: CargandoService,
              private servicioSesion: SesionService,
              private mensajeModalService: MensajeModalService) { }

  abrirSolicitudOtp() {
    this.cargandoService.cambiarEstadoCargando(false);
    this.cargandoService.cambiarEstadoCargandoLlamada(new MensajeOtp(false, ''));
    this.modalService.open(this.contenidoSolicitudOtp, {ariaLabelledBy: 'modal-basic-title' , centered: true });
  }

  cerrar() {
    this.modalService.dismissAll();
  }

  submit() {
    this.cargandoService.cambiarEstadoCargando(true);
    const sesion: Sesion = this.servicioSesion.obtenerSesionActual();
    this.cerrar();
    this.firmaOtpService.generarOtp(new SolicitudFirmaOtp(sesion.conexionId)).subscribe({
      next: (resultado) => {
        this.cargandoService.cambiarEstadoCargando(false);
        this.modalOtpComponent.abrirOtp();
      }, error: () =>
        this.mensajeModalService.mostratrModalUnaOpcion(parameter.mensajeErrorGenerico, TipoMensajeModal.cancelacion, 'Entendido',
          OpcionPasarela.Error, TipoAlerta.Error, parameter.urlRetorno)
    });
  }
}
