import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { SolicitudValidacionCodigoOtp } from '../model/solicitudValidacionCodigoOtp';
import { ValidacionIdentidadService } from '../service/validacionIdentidad.service';
import { takeWhile } from 'rxjs/operators';
import { CargandoService } from 'src/app/core/services/cargando.service';
import { RespuestaValidarCodigoOtp } from '../model/respuestaValidarCodigoOtp';
import { MensajeModalService } from 'src/app/core/services/mensaje-modal.service';
import { TipoAlerta } from 'src/app/core/enum/enumTipoAlerta';
import { ParametrosService } from '../shared/services/parametros.service';
import { TipoMensajeModal } from 'src/app/core/models/enumTipoMensajeModal';
import { SesionService } from 'src/app/core/services/sesion.service';
import { MensajeService } from '../shared/services/mensaje.service';
import { PagoCreditoService } from '../shared/services/pago-credito.service';
import { CodigoFinalizacion } from '../shared/models/enumCodigoFinalizacion';
import { CancelarProcesoOtp } from '../shared/models/cancelarProcesoOtp';
import { ResultadoCancelacionProceso } from '../shared/models/resultadoCancelacionProceso';
import { OpcionPasarela } from '../pasarela/components/models/enumOpcionPasarela';

@Component({
  selector: 'app-modal-otp',
  templateUrl: './modal-otp.component.html',
  styleUrls: ['./modal-otp.component.sass']
})
export class ModalOtpComponent implements OnInit {
  @ViewChild('contenido', { static: false }) contenido: any;
  mostrarReintento;
  mostrarMensajeCodigoIncorrecto: boolean;
  curSec;
  FormularioOTP: UntypedFormGroup;
  tituloModal: string;
  solicitudValidacionCodigoOtp: SolicitudValidacionCodigoOtp;
  template: any;
  sub: Subscription;
  tiempoOtp: number;
  contador;
  idRastreo: string;
  mensajeTiempoVencido: string;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private validacionIdentidadService: ValidacionIdentidadService,
              private Session: SesionService,
              private mensajeModalService: MensajeModalService,
              private parametrosService: ParametrosService,
              private mensajeService: MensajeService,
              private servicioPagoCredito: PagoCreditoService,
              private cargandoService: CargandoService
              ) { }

  ngOnInit() {
    this.mostrarReintento = false;
    this.mostrarMensajeCodigoIncorrecto = false;
    this.curSec = 0;
    const sesionActual = this.Session.obtenerSesionActual();
    this.idRastreo = sesionActual.conexionId;
    this.tiempoOtp = 60;
    this.parametrosService.consulta('TIEMPO_VENCIMIENTO_OTP').subscribe(result => {
      if (result.valor !== null) {
        const valorParametro = result.valor;
        this.tiempoOtp = parseInt(valorParametro, 10);
      }
    });
    this.mensajeService.consulta('FIRMA_OTP_NO_DIGITADO').subscribe( result => {
      if (result !== null) {
        this.mensajeTiempoVencido = result.descripcion;
      }
    });
    this.contador = this.tiempoOtp;
    this.Formulario();
  }

  Formulario() {
    const maxLengthOtp = 4;
    const minLengthOtp = 4;
    this.FormularioOTP = this.fb.group({
      otp: [
        '',
        [Validators.required, Validators.maxLength(maxLengthOtp),
        Validators.maxLength(minLengthOtp)]]
    });
  }

  startTimer(seconds: number) {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    const intervalo = 1000;
    const timer$ = interval(intervalo);
    this.sub = timer$
      .pipe(takeWhile(this.esCodigoCorrecto()))
      .subscribe((sec) => {
        if ((seconds - sec) <= 0) {
          this.contador = 0;
        } else {
          this.contador = seconds - sec;
        }
        this.curSec = sec;
        if (this.curSec === seconds) {
          this.tiempoFinalizado();
          this.sub.unsubscribe();
        }
      });
  }

  private esCodigoCorrecto(): (value: number, index: number) => boolean {
    return () => !this.mostrarMensajeCodigoIncorrecto;
  }

  tiempoFinalizado() {
    this.mostrarReintento = true;
    this.cancelarCompra();
  }

  abrirOtp() {
    this.ngOnInit();
    this.startTimer(this.tiempoOtp);
    this.modalService.open(
      this.contenido,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true
      }
    );
    this.tituloModal = 'titulo';
    this.template = '';
  }

  onSubmit() {
    if (this.FormularioOTP.valid) {
      this.validarCodigoOtp();
    }
  }

  private validarCodigoOtp() {
    this.consumirEndpointValidacionCodigoOtp();
  }

  private consumirEndpointValidacionCodigoOtp() {
    this.cargandoService.cambiarEstadoCargando(true);
    this.cerrarModal();
    this.mostrarMensajeCodigoIncorrecto = true;
    const codigoOtp = this.FormularioOTP.get('otp').value;
    this.solicitudValidacionCodigoOtp = new SolicitudValidacionCodigoOtp(this.idRastreo, codigoOtp);
    this.validacionIdentidadService.validarOtp(this.solicitudValidacionCodigoOtp).subscribe(resultado => {
      this.cargandoService.cambiarEstadoCargando(false);
      this.validarCodigoOtpCorrecto(resultado);
    });
  }

  private validarCodigoOtpCorrecto(respuestaValidarCodigoOtp: RespuestaValidarCodigoOtp) {
    if (respuestaValidarCodigoOtp.resultado) {
      this.mensajeModalService.mostratrModalUnaOpcion(respuestaValidarCodigoOtp.mensaje, TipoMensajeModal.cancelacion, 'Finalizar',
        OpcionPasarela.solicitudLlamada, TipoAlerta.Completado, respuestaValidarCodigoOtp.url);
    } else {
      this.mensajeModalService.mostratrModalUnaOpcion(respuestaValidarCodigoOtp.mensaje, TipoMensajeModal.cancelacion,
          'Entendido', OpcionPasarela.Error, TipoAlerta.Error, respuestaValidarCodigoOtp.url);
    }
  }

  generarAlertaCancelado(resultado: ResultadoCancelacionProceso) {
    this.mensajeModalService.mostratrModalUnaOpcion(this.mensajeTiempoVencido, TipoMensajeModal.cancelacion,
      'Entendido', OpcionPasarela.Error, TipoAlerta.Error, resultado.urlRetornoNegativo);
  }

  cancelarCompra() {
    this.cargandoService.cambiarEstadoCargando(true);
    const cancelar: CancelarProcesoOtp = {
      idrastreo: this.idRastreo ,
      idorden: '',
      estadoTransaccion: 2,
      codigoFinalizacion: CodigoFinalizacion.TimeOut
    };
    this.cerrarModal();
    this.servicioPagoCredito.cancelarProcesoPagoOtp( cancelar).subscribe(resultado => {
        this.generarAlertaCancelado(resultado);
    });
  }

  cerrarModal() {
     this.modalService.dismissAll();
  }

}
