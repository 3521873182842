import { Component, OnInit, ViewChild } from '@angular/core';
import { PagoCreditoService } from '../tu-credito-agaval/components/shared/services/pago-credito.service';
import { ResultadoConsulta } from '../tu-credito-agaval/components/shared/models/resultadoConsulta';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { Pasarela } from '../tu-credito-agaval/components/pasarela/components/models/pasarela';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Sesion } from 'src/app/core/models/sesion';
import { PasarelaService } from '../tu-credito-agaval/components/shared/services/pasarela.service';
import { OpcionPasarela } from '../tu-credito-agaval/components/pasarela/components/models/enumOpcionPasarela';
import { PlanDePagos } from 'src/app/feature/tu-credito-agaval/components/shared/models/planDePagos';
import { ActivatedRoute } from '@angular/router';
import { AlertasService } from 'src/app/core/services/Alertas.service';
import { Mensaje } from '../tu-credito-agaval/components/shared/models/mensaje';
import { CargandoComponent } from '../tu-credito-agaval/components/shared/components/cargando/cargando.component';
import { RespuestaEstadoFirmaAUsuario } from '../tu-credito-agaval/components/shared/models/respuestaEstadoFirmaAUsuario';
import { MensajeModalService } from 'src/app/core/services/mensaje-modal.service';
import { TipoMensajeModal } from 'src/app/core/models/enumTipoMensajeModal';
import { TipoAlerta } from 'src/app/core/models/enumTipoAlerta';
import { MensajeService } from '../tu-credito-agaval/components/shared/services/mensaje.service';
import { CargandoService } from 'src/app/core/services/cargando.service';
import { RespuestaVerificacionOrdenes } from 'src/app/shared/models/respuestaVerificacionOrdenes';
import { TipoMensajeRespuesta } from 'src/app/shared/models/tipoMensajeRespuesta';
import { RespuestaMensajeError } from 'src/app/shared/models/respuestaMensajeError';
import { CargandoLlamadaComponent } from '../tu-credito-agaval/components/shared/components/cargando-llamada/cargando-llamada.component';
import { CancelarPago } from '../tu-credito-agaval/components/shared/models/enumCancelarPago';
import { CodigoFinalizacion } from '../tu-credito-agaval/components/shared/models/enumCodigoFinalizacion';
import { CancelarProceso } from '../tu-credito-agaval/components/shared/models/cancelarProceso';
import { MensajeVinculacion } from '../tu-credito-agaval/components/shared/models/mensajeVinculacion';
import { RedireccionVinculacionService } from '../tu-credito-agaval/components/shared/services/redireccion-vinculacion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RespuestaAmpliacion } from 'src/app/shared/models/respuestaAmpliacion';
import { RespuestaFrontClienteActualizacion } from '../tu-credito-agaval/components/shared/models/respuestaFrontClienteActualizacion';
import { SeleccionTipoCreditoComponent } from '../tu-credito-agaval/components/seleccion-tipo-credito/seleccion-tipo-credito.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  @ViewChild('cargando', { static: false }) CargandoComponent: CargandoComponent;
  @ViewChild('cargandoLlamada', { static: false }) CargandoLlamadaComponent: CargandoLlamadaComponent;
  @ViewChild('ampliacionCupoTemplate', { static: false }) ampliacionModal: any;
  public resultadoModal: string;
  public conexionId: string;
  public opcionPasarela: Pasarela;
  private idOrden: string;
  private comercio: string;
  private esSolicitud = false;
  public visiblePasarela = false;
  public nombreCliente = '¡Bienvenido!';
  nombrePersona = "";
  mensajeAmpliacion = '';
  mostrarAmpliacion = false;
  private urlRetorno = environment.urlAgavalPorDefecto;
  ampliacionTemplate: any;
  connection: signalR.HubConnection;
  constructor(
    private servicioPagoCredito: PagoCreditoService,
    private servicioSesion: SesionService,
    private servicioPasarela: PasarelaService,
    private servicioAlerta: AlertasService,
    private servicioRedireccionVinculacion: RedireccionVinculacionService,
    private route: ActivatedRoute,
    private servicioMensajesModal: MensajeModalService,
    private servicioMensaje: MensajeService,
    private cargandoService: CargandoService,
    private modalService: NgbModal
  ) {
    this.opcionPasarela = null;
    this.conexionId = '';
    this.servicioPagoCredito = servicioPagoCredito;
    this.servicioSesion = servicioSesion;
    this.servicioPasarela = servicioPasarela;
    this.servicioRedireccionVinculacion = servicioRedireccionVinculacion;
  }

  ngOnInit() {
    this.visiblePasarela = false;
    this.route.paramMap.subscribe(params => {
      if (params.has('Comercio')) {
        this.idOrden = params.get('IdOrden');
        this.comercio = params.get('Comercio');
      } else {
        this.idOrden = params.get('IdOrden');
        this.esSolicitud = true;
      }
    });
    this.ConexionSignalR();
    this.servicioPasarela.cargando.subscribe(opcion => {
      this.CargandoComponent.cambiarEstadoCargando(true);
    });
    this.servicioPasarela.nombreCliente.subscribe(nombre => {
      this.nombreCliente = '¡Hola ' + nombre + '!';
      this.nombrePersona = nombre;
    });
    this.servicioMensajesModal.PantallaHome.subscribe(resultado => {
      if (resultado === 'SI') {
        this.inciarPago();
      } else {

        if (resultado === 'NO') {
          const cancelar: CancelarProceso = {
            idrastreo: '',
            idorden: this.idOrden,
            idproceso: CancelarPago.VerificarOrden,
            codigoFinalizacion: CodigoFinalizacion.NoExistencia
          };
          this.servicioPagoCredito.cancelarProcesoPago(cancelar).subscribe();
        }
        if ( this.servicioMensajesModal.inIframe() ) {
          window.parent.postMessage('RetornoNegativo', '*');
        } else {
          window.location.href = this.urlRetorno;
        }
      }
    });

    this.servicioMensajesModal.IniciarPago.subscribe(resultado => {
      this.CargandoComponent.cambiarEstadoCargando(true);
      this.retomarPago();
    });

    this.cargandoService.CambioEstadoCargadnoEmitter.subscribe(estado => {
      this.CargandoComponent.cambiarEstadoCargando(estado);
    });
    this.cargandoService.CambioEstadoCargandoLlamadaEmitter.subscribe(mensajeOtp => {
      this.CargandoLlamadaComponent.cambiarEstadoCargando(mensajeOtp);
    });
  }

  ConexionSignalR() {
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(environment.signalR, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();
    reconectar(this.connection);
    async function reconectar(conn: any) {
      conn.start().catch(e => {
        sleep(70000);
        reconectar(conn);
      }
      );
    }
    this.connection.keepAliveIntervalInMilliseconds = 800000;
    this.connection.start().then((value: void) => {
    }).catch((err) => {
      console.log(err);
    });
    this.connection.onclose((e) => {
      reconectar(this.connection);
    });

    async function sleep(msec) {
      return new Promise(resolve => setTimeout(resolve, msec));
    }

    this.connection.on('obternerConexionId', (id: string) => {
      this.conexionId = id;
      this.iniciarSesion();
      this.inciarPago();      
      this.CargandoComponent.cambiarEstadoCargando(true);  
    });

    this.connection.on('obtenerRespuestaValidacionCliente', (mensaje: Mensaje) => {
      this.CargandoComponent.cambiarEstadoCargando(false);
      this.opcionPasarela = new Pasarela();
      this.opcionPasarela.opcion = OpcionPasarela.validacionCliente;
      this.opcionPasarela.data = mensaje;
      this.visiblePasarela = true;
      this.servicioPasarela.abrirPasarela(this.opcionPasarela);
    });

    this.connection.on('redireccionarClienteVinculacion', (mensaje: MensajeVinculacion) => {
      this.servicioMensajesModal.mostratrModalUnaOpcion(mensaje.mensajeModal, TipoMensajeModal.confirmacion, 'Entendido',
        OpcionPasarela.RedireccionVinculacion, TipoAlerta.Informativa, null, mensaje);
    });

    this.connection.on('redireccionarClienteActualizacion', (mensaje: RespuestaFrontClienteActualizacion) => {
      this.servicioMensajesModal.mostratrModalUnaOpcion(mensaje.mensajeModal, TipoMensajeModal.confirmacion, 'Entendido',
        OpcionPasarela.RedireccionActualizacion, TipoAlerta.Informativa, null, mensaje);
    });

    this.connection.on('enviarAmpliacionCupo', (mensaje: any) => {
      this.CargandoComponent.cambiarEstadoCargando(false);
      this.mensajeAmpliacion = mensaje.mensaje;
      this.modalService.open(
        this.ampliacionModal,
        { size: 'sm', keyboard: false, backdrop: 'static', centered: true });
    });

    this.connection.on('obtenerPlanDePagos', (planesDePago: Array<PlanDePagos>) => {
      this.CargandoComponent.cambiarEstadoCargando(true);
      this.opcionPasarela = new Pasarela();
      this.opcionPasarela.opcion = OpcionPasarela.planDePagos;
      this.opcionPasarela.data = planesDePago;
      this.visiblePasarela = true;
      this.CargandoComponent.cambiarEstadoCargando(false);
      this.servicioPasarela.abrirPasarela(this.opcionPasarela);
    });

    this.connection.on('obtenerRespuestaLlamadaDocumentos', (respuestaLlamada: RespuestaEstadoFirmaAUsuario) => {
      this.servicioPasarela.abrirSolicitudLlamada(respuestaLlamada);
    });

    this.connection.on('obtenerIdentificadorSeguridad', (identificadorSeguridad: any) => {
      sessionStorage.setItem('idRastreo', identificadorSeguridad.idRastreo);
      sessionStorage.setItem('identificadorSeguridad', identificadorSeguridad.identificador);
    });

    this.connection.on('obtenerRespuestaVerificacionOrdenes', (respuestaVerificacion: RespuestaVerificacionOrdenes) => {
      this.urlRetorno = respuestaVerificacion.urlRetorno;
      switch (respuestaVerificacion.tipoRespuesta) {
        case TipoMensajeRespuesta.Advertencia:
          this.servicioMensajesModal.mostratrModalUnaOpcion(respuestaVerificacion.descripcion, TipoMensajeModal.confirmacion, 'Entendido',
            OpcionPasarela.Home, TipoAlerta.Informativa);
          break;
        case TipoMensajeRespuesta.Informativa:
          this.servicioMensajesModal.mostratrModalMultiopcion(respuestaVerificacion.descripcion, TipoMensajeModal.confirmacion, 'SI', 'NO',
            OpcionPasarela.Home, TipoAlerta.Informativa);
          break;
        case TipoMensajeRespuesta.Error:
          this.servicioMensajesModal.mostratrModalUnaOpcion(respuestaVerificacion.descripcion, TipoMensajeModal.confirmacion, 'Entendido',
            OpcionPasarela.Home, TipoAlerta.Error);
          break;
      }
    });

    this.connection.on('obtenerRespuestaIniciarOrdenIntegracion', (respuestaVerificacion: RespuestaVerificacionOrdenes) => {
      this.urlRetorno = respuestaVerificacion.urlRetorno;
      this.servicioMensajesModal.mostratrModalUnaOpcion(respuestaVerificacion.descripcion, TipoMensajeModal.confirmacion, 'Entendido',
        OpcionPasarela.Home, TipoAlerta.Error);
    });

    this.connection.on('obtenerMensajeError', (respuestaMensajeError: RespuestaMensajeError) => {
      this.servicioMensajesModal.mostratrModalUnaOpcion(respuestaMensajeError.mensaje, TipoMensajeModal.confirmacion, 'Entendido',
        OpcionPasarela.Error, TipoAlerta.Error, respuestaMensajeError.urlRetorno);
    });

    this.connection.on('obtenerRespuestaAmpliacion', (respuesta: RespuestaAmpliacion) => {
      this.CargandoComponent.cambiarEstadoCargando(false);
      this.modalService.dismissAll();
      if (respuesta.cancelarCompra) {
        this.servicioMensajesModal.mostratrModalUnaOpcion(respuesta.mensaje, TipoMensajeModal.confirmacion, 'Entendido',
          OpcionPasarela.Error, TipoAlerta.Advertencia, respuesta.retorno);
      } else {
        this.servicioMensajesModal.mostratrModalUnaOpcion(respuesta.mensaje, TipoMensajeModal.confirmacion, 'Entendido',
          OpcionPasarela.iniciarPago, TipoAlerta.Completado);
      }
    });
  }

  inciarPago() {
    const dialogRef = this.modalService.open(SeleccionTipoCreditoComponent,{centered: true});
    dialogRef.componentInstance.nombre = this.nombrePersona;
    dialogRef.result.then(tipoCredito=> {     
      if (tipoCredito && this.conexionId !== '' && !this.esSolicitud) {              
        this.servicioPagoCredito.iniciarPagoConCredito(this.idOrden, this.comercio, this.conexionId, false,tipoCredito.tipo).subscribe(resultado => {
          this.obtenerResultadoDeInicioPago(resultado);
          this.servicioPasarela.abrirCarganDoPasarela(OpcionPasarela.validacionCliente);
        });
      } else {
        this.servicioPagoCredito.iniciarPagoConCreditoIntegracion(this.idOrden, this.conexionId).subscribe(resultado => {
          this.obtenerResultadoDeInicioPago(resultado);
          this.servicioPasarela.abrirCarganDoPasarela(OpcionPasarela.validacionCliente);
        });
      }
    });
    
  }

  retomarPago() {
    if (this.conexionId !== '' && !this.esSolicitud) {
      this.servicioPagoCredito.iniciarPagoConCredito(this.idOrden, this.comercio, this.conexionId, true).subscribe(resultado => {
        this.obtenerResultadoDeInicioPago(resultado);
        this.servicioPasarela.abrirCarganDoPasarela(OpcionPasarela.validacionCliente);
      });
    } else {
      this.servicioPagoCredito.iniciarPagoConCreditoIntegracion(this.idOrden, this.conexionId).subscribe(resultado => {
        this.obtenerResultadoDeInicioPago(resultado);
        this.servicioPasarela.abrirCarganDoPasarela(OpcionPasarela.validacionCliente);
      });
    }
  }

  obtenerResultadoDeInicioPago(resultado: ResultadoConsulta) {
    if (!resultado.resultadoConsulta) {
      this.servicioMensaje.consulta('NOTIFICAR_ERROR_CARGANDO_PASARELA').subscribe(resultadoMensaje => {
        let mensaje = 'Error cargando pasarela de pagos';
        if (resultadoMensaje && resultadoMensaje.descripcion) {
          mensaje = resultadoMensaje.descripcion;
        }
        this.servicioMensajesModal.mostratrModalUnaOpcion(mensaje, TipoMensajeModal.confirmacion, 'Entendido',
          OpcionPasarela.Home, TipoAlerta.Error);
      });
    }
  }

  iniciarSesion() {
    if (this.conexionId !== '') {
      const sesionActual: Sesion = { conexionId: this.conexionId };
      this.servicioSesion.iniciarSesion(sesionActual);
    }
  }

  public obtenerResultadoModal(resultadoModal: string) {
    this.resultadoModal = resultadoModal;
  }

  tiempoFinalizadoAmpliacion() {
    this.CargandoComponent.cambiarEstadoCargando(true);
    this.modalService.dismissAll();
    this.connection.onclose(async () => { });
    this.servicioPagoCredito.enviarUrlCompra(this.conexionId).subscribe(respuesta => {
      this.CargandoComponent.cambiarEstadoCargando(false);
      this.servicioMensajesModal.mostratrModalUnaOpcion(respuesta.mensaje, TipoMensajeModal.confirmacion, 'Entendido',
        OpcionPasarela.Error, TipoAlerta.Informativa, 'https://www.agaval.com/');
    });
  }
}
