import { DetallePlanDePago } from './detallePlanDePago';


export class PlanDePagos {
    estadoPagoId?: string;
    id?: string;
    numeroCuotas: number;
    preferida?: boolean;
    sugerida?: number;
    totalFianza: number;
    totalIntereses: number;
    totalInteresesIva: number;
    totalItems: number;
    totalTecnologia: number;
    totalValorCompra: number;
    totalValorSeguro: number;
    totalCapital: number;
    detallesPlanDePago?: Array<DetallePlanDePago> = [];
}
