import { Injectable, Output, EventEmitter } from '@angular/core';
import { MensajeModal } from '../models/mensajeModal';
import { TipoMensajeModal } from '../models/enumTipoMensajeModal';
import { OpcionPasarela } from 'src/app/feature/tu-credito-agaval/components/pasarela/components/models/enumOpcionPasarela';

@Injectable({
  providedIn: 'root'
})
export class MensajeModalService {
  @Output() ValidacionCliente: EventEmitter<string> = new EventEmitter();
  @Output() ListaPlanDePago: EventEmitter<string> = new EventEmitter();
  @Output() ListaSolicitudLlamada: EventEmitter<string> = new EventEmitter();
  @Output() ValidacionCelular: EventEmitter<string> = new EventEmitter();
  @Output() ventanaModal: EventEmitter<MensajeModal> = new EventEmitter();
  @Output() ventanaContrato: EventEmitter<any> = new EventEmitter();
  @Output() PantallaHome: EventEmitter<any> = new EventEmitter();
  @Output() IniciarPago: EventEmitter<any> = new EventEmitter();

modal(modal: MensajeModal) {
  this.ventanaModal.emit(modal);
}

mostratrModalMultiopcion(
                        descripcion: string,
                        tipoMensajemodal: TipoMensajeModal,
                        tituloBotonPrimario: string,
                        tituloBotonSecundario: string,
                        opcion: OpcionPasarela,
                        tipoMensaje: number  ) {

  const modal = new MensajeModal();
  modal.mensaje = descripcion;
  modal.tipoModal = tipoMensajemodal;
  modal.tituloBotonPrimario = tituloBotonPrimario;
  modal.tituloBotonSecundario = tituloBotonSecundario;
  modal.opcion = opcion;
  modal.tipoMensajeAlerta = tipoMensaje;
  modal.basico = true;
  this.ventanaModal.emit(modal);
}

mostratrModalAvisoCelular(
  descripcion: string,
  tipoMensajemodal: TipoMensajeModal,
  tituloBotonPrimario: string,
  tituloBotonSecundario: string,
  opcion: OpcionPasarela,
  tipoMensaje: number
) {

const modal = new MensajeModal();
modal.mensaje = descripcion;
modal.tipoModal = tipoMensajemodal;
modal.tituloBotonPrimario = tituloBotonPrimario;
modal.tituloBotonSecundario = tituloBotonSecundario;
modal.opcion = opcion;
modal.tipoMensajeAlerta = tipoMensaje;
modal.basico = false;
this.ventanaModal.emit(modal);
}

mostratrModalUnaOpcion(
                      descripcion: string,
                      tipoMensajemodal: TipoMensajeModal,
                      tituloBotonPrimario: string,
                      opcion: OpcionPasarela,
                      tipoMensaje: number,
                      urlRetorno?: string,
                      data?: any ) {
  const modal = new MensajeModal();
  modal.mensaje = descripcion;
  modal.tipoModal = tipoMensajemodal;
  modal.tituloBotonPrimario = tituloBotonPrimario;
  modal.tituloBotonSecundario = '';
  modal.opcion = opcion;
  modal.tipoMensajeAlerta = tipoMensaje;
  modal.urlRetorno = urlRetorno;
  modal.data = data;
  modal.basico = true;
  this.ventanaModal.emit(modal);
}

responderValidacionCliente(opcionUnoEscojida: string) {
  this.ValidacionCliente.emit(opcionUnoEscojida);
}

responderPlanDePago(opcionUnoEscojida: string) {
  this.ListaPlanDePago.emit(opcionUnoEscojida);
}

responderSolicidudDeLlamada(opcionUnoEscojida: string) {
  this.ListaSolicitudLlamada.emit(opcionUnoEscojida);
}

responderValidacionCelular(opcionUnoEscojida: string) {
  this.ValidacionCelular.emit(opcionUnoEscojida);
}

responderHome(opcionUnoEscojida: string) {
  this.PantallaHome.emit(opcionUnoEscojida);
}

responderIniciarPago(opcionUnoEscojida: string) {
  this.IniciarPago.emit(opcionUnoEscojida);
}

abrirContrato() {
  this.ventanaContrato.emit(' ');
}

inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }

}
}
