import { Component, OnInit, ViewChild } from '@angular/core';
import { Pasarela } from './components/models/pasarela';
import { PasarelaService } from '../shared/services/pasarela.service';
import { OpcionPasarela } from './components/models/enumOpcionPasarela';
import { ValidarUsuarioComponent } from '../validar-usuario/validar-usuario.component';
import { PlanesPagoComponent } from '../planes-pago/planes-pago.component';
import { SolicitudLlamadaComponent } from '../solicitud-llamada/solicitud-llamada.component';

@Component({
  selector: 'app-pasarela',
  templateUrl: './pasarela.component.html'
})
export class PasarelaComponent implements OnInit {
  mostrarPasarela = false;
  opcionPasarela: Pasarela;
  opcionTitulo: number;
  mostrarValidacionUsuario = false;
  mostrarPlanDePagos = false;
  mostrarSolicitudLlamada = false;
  opcionesCssDelaBarra: Array<string>;
  @ViewChild('validarcliente', {static: false}) validarUsuarioComponent: ValidarUsuarioComponent;
  @ViewChild('planespago', {static: false}) planesPagoComponent: PlanesPagoComponent;
  @ViewChild('solicitudLlamada', {static: false}) solicitudLlamadaComponent: SolicitudLlamadaComponent;
  constructor( private  servicioPasarela: PasarelaService) {
    this.mostrarPasarela = false;
    this.opcionTitulo = 0;
    this.opcionesCssDelaBarra = ['', '', '', '', ''];
  }

  ngOnInit() {
    this.servicioPasarela.pasarela.subscribe(opcion => {
      this.visibilizarOpcion(opcion);
    });
  }

  AsignarPasoCompletado() {
    this.opcionesCssDelaBarra = ['', '', '', '', ''];
    for (let posicion = 0; posicion < this.opcionesCssDelaBarra.length - 1; posicion++) {
      let claseCss = '';
      if (this.opcionPasarela.opcion > posicion) {
        claseCss = 'complete';
      } else {
        claseCss = (this.opcionPasarela.opcion === posicion) ? '' : 'nocomplete' ;
      }
      this.opcionesCssDelaBarra [posicion] = claseCss;
    }
  }

  visibilizarOpcion(opcion: Pasarela) {
    this.mostrarPasarela  = true;
    this.opcionPasarela = opcion;
    this.AsignarPasoCompletado();
    this.validarUsuarioComponent.mostrarPantalla = false;
    this.planesPagoComponent.mostrarPantalla = false;
    this.solicitudLlamadaComponent.mostrarPantalla = false;
    this.mostrarValidacionUsuario = false;
    this.mostrarPlanDePagos = false;
    switch ( opcion.opcion) {
      case OpcionPasarela.validacionCliente: {
        this.mostrarValidacionUsuario = true;
        this.validarUsuarioComponent.mostrarPantalla = true;
        this.validarUsuarioComponent.clasificarMensaje(opcion.data);
        break;
      }
      case OpcionPasarela.planDePagos: {
        this.mostrarPlanDePagos = true;
        this.planesPagoComponent.mostrarPantalla = true;
        this.planesPagoComponent.llenarPlanDepagos(opcion.data);
        break;
      }
      case OpcionPasarela.solicitudLlamada: {
        this.mostrarSolicitudLlamada = true;
        this.solicitudLlamadaComponent.mostrarPantalla = true;
        this.solicitudLlamadaComponent.iniciarPantalla(opcion.data);
        break;
      }
    }
  }
}
