import { Component, OnInit, ViewChild } from '@angular/core';
import { RespuestaIniciarFirma } from '../shared/models/respuestaIniciarFirma';
import { PagoCreditoService } from '../shared/services/pago-credito.service';
import { AlertasService } from 'src/app/core/services/Alertas.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Sesion } from 'src/app/core/models/sesion';
import { CancelarPago } from '../shared/models/enumCancelarPago';
import { CodigoFinalizacion } from '../shared/models/enumCodigoFinalizacion';
import { MensajeService } from '../shared/services/mensaje.service';
import { TipoAlerta } from 'src/app/core/models/enumTipoAlerta';
import { MensajeModalService } from 'src/app/core/services/mensaje-modal.service';
import { TipoMensajeModal } from 'src/app/core/models/enumTipoMensajeModal';
import { OpcionPasarela } from '../pasarela/components/models/enumOpcionPasarela';
import { PasarelaService } from '../shared/services/pasarela.service';
import { RespuestaEstadoFirmaAUsuario } from '../shared/models/respuestaEstadoFirmaAUsuario';
import { ResultadoCancelacionProceso } from '../shared/models/resultadoCancelacionProceso';
import { environment } from 'src/environments/environment';
import { CargandoService } from 'src/app/core/services/cargando.service';
import { MensajeOtp } from '../shared/models/mensajeOtp';
import { CancelarProceso } from '../shared/models/cancelarProceso';
import { TemporizacionComponent } from '../shared/components/temporizacion/temporizacion.component';
import { Temporizador } from '../shared/models/temporizador';
import { ParametrosService } from '../shared/services/parametros.service';
import { SolicitudOtpComponent } from '../solicitud-otp/solicitud-otp.component';

@Component({
  selector: 'app-solicitud-llamada',
  templateUrl: './solicitud-llamada.component.html',
  styleUrls: ['./solicitud-llamada.component.sass']
})
export class SolicitudLlamadaComponent implements OnInit {
  @ViewChild('temporizador', {static: false}) temporizadorComponente: TemporizacionComponent;
  @ViewChild('solicitudOtp', {static: false}) solicitudOtpComponent: SolicitudOtpComponent;
  public SomeIcon = '../../../assets/img/ic-agaval-bulb.svg';
  public descripcion = '';
  public mostrarPantalla = false;
  public botonLlamar = 'Llamar';
  private urlRetorno = environment.urlAgavalPorDefecto;
  deshabilitarLlamada = false;
  deshabilitarCancelar = false;
  seVisualizoTimeOut = false;
  datos: RespuestaIniciarFirma;
  llamadaPendiente = true;
  tiempoReintentoPendiente: number;
  constructor(
    private servicioPagoCredito: PagoCreditoService,
    private aletasServicio: AlertasService,
    private servicioSesion: SesionService,
    private servicioMensajes: MensajeService,
    private servicioMensajesModal: MensajeModalService,
    private servicioPasarela: PasarelaService,
    private cargandoService: CargandoService,
    private parametroService: ParametrosService
    ) { }

  ngOnInit() {
    this.servicioMensajesModal.ListaSolicitudLlamada.subscribe( resultado => {
      this.mostrarPantalla = true;
      if (resultado === 'SI') {
        this.cancelarProceso();
      } else if (resultado === 'Reintentar') {
        this.refrescarPantalla();
      } else if (resultado !== 'NO') {
        this.cargandoService.cambiarEstadoCargando(true);
        if (this.servicioMensajesModal.inIframe()) {
          window.parent.postMessage('RetornoPositivo', '*');
        } else {
          window.location.href = this.urlRetorno;
        }
      }
      this.deshabilitarCancelar = false;
    });

    this.servicioPasarela.SolicitudLlamada.subscribe((resultado: RespuestaEstadoFirmaAUsuario) => {
      if (this.llamadaPendiente) {
        this.llamadaPendiente = false;
        this.temporizadorComponente.stop();
        if (resultado && resultado.urlRetorno) {
            this.urlRetorno = resultado.urlRetorno;
        }
        if (resultado.estadoProcesoFirma && !this.seVisualizoTimeOut) {
          this.generarMensajeModal(resultado.mensaje , false);
        } else {
          if (resultado.reintentos === 1) {
            this.servicioMensajesModal.mostratrModalUnaOpcion(resultado.mensaje, TipoMensajeModal.confirmacion, 'Reintentar',
            OpcionPasarela.solicitudLlamada, TipoAlerta.Error);
          } else {
            this.solicitudOtpComponent.abrirSolicitudOtp();
          }
        }
      }
    });
  }

  iniciarPantalla(datos: RespuestaIniciarFirma) {
    this.mostrarPantalla = true;
    this.descripcion = datos.mensaje;
    this.datos = datos;
    this.obtenerTiempoReintento('TIEMPO_REINTENTO_LLAMADA');
  }

  refrescarPantalla() {
    const sesion: Sesion = this.servicioSesion.obtenerSesionActual();
    this.deshabilitarLlamada = false;
    this.servicioPagoCredito.crearFirma(sesion.conexionId, this.datos.numeroCuota).subscribe( resultado => {
      if ( !resultado ) {
        this.aletasServicio.error('Error durante el reintento');
      } else {
        this.botonLlamar = 'Reintentar llamada';
        this.cargandoService.cambiarEstadoCargandoLlamada(new MensajeOtp(false, resultado.otpTexto));
        this.iniciarPantalla(resultado);
      }
    });
  }

  llamar() {
    this.cargandoService.cambiarEstadoCargandoLlamada(new MensajeOtp(true, this.datos.otpTexto));
    const sesion: Sesion = this.servicioSesion.obtenerSesionActual();
    this.deshabilitarLlamada = true;
    this.deshabilitarCancelar = true;
    this.temporizadorComponente.IniciarTemporizacion(180000);
    this.llamadaPendiente = true;
    this.temporizadorComponente.IniciarTemporizacionPendiente(this.tiempoReintentoPendiente);
    this.temporizadorComponente.start();
    this.servicioPagoCredito.hacerLlamada(this.datos , sesion.conexionId ).subscribe( resultado => {
      if ( !resultado ) {
        this.aletasServicio.error('Error en la llamada');
      }
    });
  }

  MostrarTimeOut( mostrar: Temporizador) {
    if (mostrar.estado) {
      return this.MostrarPendiente();
    }
  }

  MostrarPendiente() {
    if (this.llamadaPendiente) {
      this.temporizadorComponente.stop();
      const sesion: Sesion = this.servicioSesion.obtenerSesionActual();
      this.servicioPagoCredito.reintentoLlamada(sesion.conexionId).subscribe( resultado => {
        if (resultado.respuesta === 'Pendiente') {
          this.llamadaPendiente = false;
          this.mensajeOperacion('REINTENTO_LLAMADA', 'Reintentar', this.datos.nombreCliente);
        } else if (resultado.respuesta === 'fail') {
          this.llamadaPendiente = false;
          this.solicitudOtpComponent.abrirSolicitudOtp();
        } else if (resultado.respuesta === 'OK') {
          this.llamadaPendiente = true;
        }
      });
    }
  }

  mensajeOperacion(mensaje: string, textoAlerta: string, nombreCliente: string) {
    let mensajeRespueta = ' ';
    this.servicioMensajes.consulta(mensaje).subscribe(resultadoLlamada => {
      if (resultadoLlamada != null) {
        mensajeRespueta = resultadoLlamada.descripcion;
        if (mensajeRespueta.indexOf ('{0}') !== -1) {
          mensajeRespueta = mensajeRespueta.replace('{0}', nombreCliente);
        }
      } else {
        mensajeRespueta = 'Error en la aplicación';
      }
      this.servicioMensajesModal.mostratrModalUnaOpcion(mensajeRespueta, TipoMensajeModal.confirmacion, textoAlerta,
        OpcionPasarela.solicitudLlamada, TipoAlerta.Error);
    });
  }

  obtenerTiempoReintento(parametro: string) {
    this.parametroService.consulta(parametro).subscribe(resultado => {
      if (resultado != null) {
        this.tiempoReintentoPendiente = Number(resultado.valor);
      } else {
        this.tiempoReintentoPendiente = 60000;
      }
    });
  }

  cancelar() {
    this.servicioMensajes.consulta('NOTIFICACION_CANCELAR_PAGO').subscribe(resultado => {
       if (resultado != null) {
        this.deshabilitarCancelar = true;
        this.generarMensajeModal(resultado.descripcion , true);
       } else {
        this.servicioMensajesModal.mostratrModalUnaOpcion('Error en la aplicación', TipoMensajeModal.confirmacion, 'Entendido',
        OpcionPasarela.solicitudLlamada, TipoAlerta.Error);
       }
    });
  }

  generarMensajeModal(mensaje: string , multiOpcion: boolean) {
    if (multiOpcion) {
      this.servicioMensajesModal.mostratrModalMultiopcion(mensaje, TipoMensajeModal.confirmacion, 'SI', 'NO',
      OpcionPasarela.solicitudLlamada, TipoAlerta.Informativa);
    } else {
      this.servicioMensajesModal.mostratrModalUnaOpcion(mensaje, TipoMensajeModal.cancelacion, 'Finalizar',
      OpcionPasarela.solicitudLlamada, TipoAlerta.Completado, this.urlRetorno);
    }
  }

  cancelarProceso() {
    const sesion: Sesion = this.servicioSesion.obtenerSesionActual();
    this.mostrarPantalla = false;
    this.cargandoService.cambiarEstadoCargando(true);
    const cancelar: CancelarProceso = {
      idrastreo: sesion.conexionId ,
      idorden: '',
      idproceso : CancelarPago.Llamada,
      codigoFinalizacion: CodigoFinalizacion.Llamada
    };
    this.servicioPagoCredito.cancelarProcesoPago(cancelar).subscribe((resultado: ResultadoCancelacionProceso) => {
      if (this.servicioMensajesModal.inIframe()) {
        window.parent.postMessage('RetornoNegativo', '*');
      } else {
        if (resultado && resultado.urlRetornoNegativo) {
          this.urlRetorno = resultado.urlRetornoNegativo;
        }
        window.location.href = this.urlRetorno;
      }
    });
  }
}
